import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

//import Details from "./pages/details/details";
import Home from "./pages/home/home";
import Info from "./pages/info";
import Swap from "./pages/swap/swap";
import Wallet from "./pages/wallet/wallet";
import './App.css';

class App extends React.Component {
  render() {
  return (
    <div className="App">
        <Router>
      <div>
        <Switch>
          {/* <Route exact path="/details">
            <Details></Details>
          </Route> */}
          <Route exact path="/swap">
            <Swap></Swap>
          </Route> 
          <Route exact path="/wallet">
            <Wallet></Wallet>
          </Route> 
          <Route exact path="/">
            <Info></Info>
          </Route>  
          <Route exact path="/game/:referral?">
            <Home></Home>
          </Route>  
               
        </Switch>
      </div>
    </Router>
    </div>
  );
  }
}

export default App;
