import React, { Component} from 'react';
import Frame from "../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

class Info extends React.Component {
    constructor(props) {
      super(props);
     
    }

    render() {   
      return (
        <Frame withHeader={false} withFooter={true} isHome={false}>

          <header>
            <div className="container">
              <div className="header_box">
                <nav className="navbar navbar-expand-md">
                  <div className="logo">
                      <a className="navbar-brand" href="/">
                        <img src="img/logo.png" alt="" />
                      </a>
                  </div>
                  <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-expanded="false">
                    <img src="img/menu.png" alt="" />
                  </button>
                
                  <div className="navbar-collapse collapse" id="collapsibleNavbar">
                    <ul class='navbar-nav mr-auto'></ul>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                          <a className="btn btn-round btn-primary wallet_btn btn-lg" href="/game">
                            <img src='img/explore.png' /><span>Explore More</span>
                          </a>  
                        </li>
                        <li className="nav-item">
                          <a href="#" className="btn btn-round wallet_btn btn-primary btn-lg">
                            <img src='img/play-store-icon.png' /><span>Download App</span>
                          </a>
                        </li>
                    </ul>
                  </div>
                </nav>
                
              </div>
            </div>
          </header>

          <div className="header_padding"></div>

          <section className="home_banner position-relative pb-5">
            <div className="shape right_shape"></div>
            <div className="shape2 left_shape"></div>
            <div className="banner_bg pt-5">
              <div className="container">
                <div className="banner_text_box banner_text text-center">
                  <h1>Decentralized casino gaming</h1>
                  <p>Xakpot redefine the very essence of winning in number games. The platform is designed to elevate your gaming experience to new heights, offering a secure platform.</p>
                  <a href="#" className="btn mt-3 btn-round wallet_btn btn-primary btn-lg">
                    <img src="img/play-store-icon.png" /><span>Download App</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="banner_slide_img">
                <div className="banner_box_inner">
                  <img className="mobile-frame" src="img/mobile-frame.png" alt="" />
                  <div className="items_img item_one">
                      <div className="items">
                          <img src="img/screen1.jpg" alt="" />
                      </div>
                      <div className="items">
                          <img src="img/screen2.jpg" alt="" />
                      </div>
                      <div className="items">
                          <img src="img/screen3.jpg" alt="" />
                      </div>
                      <div className="items">
                          <img src="img/screen4.jpg" alt="" />
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-5 pb-5 position-relative">
          
            <div className="container">
              <div className="row align-items-center mb-5">
                <div className="col-md-6 col-lg-7">
                  <div className="heading-text mb-4">
                    <h2 className="mb-3">User-friendly wallet dashboard.</h2>
                    <h5>Easily manage multiple cryptocurrencies within a secure environment.</h5>
                  </div>
                  <p>Xakpot always prioritizes transparency and convenience, empowering users to manage multiple crypto exchanges effortlessly within a protected environment. The Wallet Dashboard serves as your control center, providing real-time insights into your current wallet balance at your fingertips.</p>
                  <a href="#" className="btn mt-3 btn-round wallet_btn btn-primary btn-lg">
                    <img src="img/play-store-icon.png" /><span>Download App</span>
                  </a>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="app_screen_box">
                    <img className="img-fluid" src="img/app_screen1.png" alt="" />
                  </div>
                </div>
              </div>
              
              <div className="row align-items-center mb-5">
                <div className="col-md-6 col-lg-5">
                  <div className="app_screen_box_2">
                    <img className="img-fluid" src="img/app_screen2.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="heading-text mb-4">
                    <h2 className="mb-3">Gaming Mobile Platform</h2>
                    <h5>We understand that true success in number games goes beyond chance.</h5>
                  </div>
                  <p>As the foremost decentralized casino platform globally, Xakpot is committed to providing a superior level of entertainment and opportunities to players worldwide. Our innovative approach empowers you to select and play with your favorite numbers, creating an immersive gaming environment where your choices and strategies matter.</p>
                  <a href="#" className="btn mt-3 btn-round wallet_btn btn-primary btn-lg">
                    <img src="img/play-store-icon.png" /><span>Download App</span>
                  </a>
                </div>
              </div>

              <div className="row align-items-center mb-5">
                <div className="col-md-6 col-lg-7">
                  <div className="heading-text mb-4">
                    <h2 className="mb-3">Secure Fund Effortlessly</h2>
                    <h5>Experience the freedom to move funds effortlessly</h5>
                  </div>
                  <p>At Xakpot, your transactions are fortified by cutting-edge blockchain technology, ensuring an impenetrable layer of security. Send, receive, or exchange your assets with absolute confidence within our decentralized platform. </p>
                  <a href="#" className="btn mt-3 btn-round wallet_btn btn-primary btn-lg">
                    <img src="img/play-store-icon.png" /><span>Download App</span>
                  </a>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="app_screen_box">
                    <img className="img-fluid" src="img/app_screen3.png" alt="" />
                  </div>
                </div>
              </div>

            </div>
          </section>

          <section className="pt-3 pb-5 position-relative">
            <div className="shape right_shape"></div>
            <div className="shape left_shape"></div>
            <div className="container">
              <div className="heading-text middle_heading mb-4">
                <h2 className="mb-3">Why choose Xakpot Mobile Wallet?</h2>
                <p>Our platform offers a diverse range of options for you to explore and conquer.</p>
              </div>
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <div className="why_xakpot_box">
                    <span><img src="img/icon1.png" alt="" /></span>
                    <h4>SECURE</h4>
                    <p>Xakpot ensures top-tier security through cutting-edge blockchain technology. The decentralized platform guarantees the integrity of every game, employing robust encryption and smart contracts to safeguard user data and transactions.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="why_xakpot_box">
                    <span><img src="img/icon2.png" alt="" /></span>
                    <h4>PRIVATE</h4>
                    <p>With a focus on privacy, Xakpot respects your personal information and keeps it confidential, allowing you to engage in gameplay without compromising your privacy. We ensure a secure environment for you to enjoy the game challenges.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="why_xakpot_box">
                    <span><img src="img/icon3.png" alt="" /></span>
                    <h4>USER-FRIENDLY</h4>
                    <p>Xakpot offers an intuitive and engaging interface designed for ease of use. Whether you're a seasoned player or new to decentralized casino gaming, our platform provides a seamless and accessible experience.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="why_xakpot_box">
                    <span><img src="img/icon4.png" alt="" /></span>
                    <h4>RELIABLE</h4>
                    <p>Powered by blockchain, Xakpot's decentralized platform guarantees a level playing field for all participants. The decentralized nature of our platform ensures that games are fair and transparent.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="why_xakpot_box">
                    <span><img src="img/icon5.png" alt="" /></span>
                    <h4>GAMING EXPERIENCE</h4>
                    <p>Our mobile wallet provides an innovative gaming environment where players' choices matter. By enabling users to select and play with their favorite numbers, we foster an immersive experience, that goes beyond traditional casino games.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="why_xakpot_box">
                    <span><img src="img/icon6.png" alt="" /></span>
                    <h4>REWARD EARNING</h4>
                    <p>Xakpot is dedicated to providing an avenue for players to refine their skills and earn rewards. By playing with their chosen numbers, users can progress in their mastery of the game, earn substantial earnings, and achieve success.</p>
                  </div>
                </div>
              </div>

            </div>
          </section>

          <section className="pt-4 pb-5 position-relative">
         
            <div className="container">
              <div className="heading-text middle_heading mb-5">
                <h2>Frequently Asked Questions (FAQs)</h2>
              </div>
              <div className="md_container">
                <div className="accordion_border">
                  <div className="accordion_head">
                    <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                    How does Xakpot ensure security and transparency for its players?
                  </div>
                  <div className="accordion_body">
                    <p>Security, fairness, and transparency are paramount at Xakpot. Our platform operates on cutting-edge blockchain technology, guaranteeing the integrity of every game. This decentralized approach ensures that game outcomes are verifiable and transparent, fostering an environment of trust for our players.</p>
                  </div>
                </div>
                <div className="accordion_border">
                  <div className="accordion_head">
                    <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                    Is Xakpot safe for transactions?
                  </div>
                  <div className="accordion_body">
                    <p>Xakpot ensures a seamless and secure transaction process by leveraging cutting-edge blockchain technology. Users have the freedom to move funds effortlessly within the decentralized platform, which is supported by an impenetrable layer of security. </p>
                  </div>
                </div>
                <div className="accordion_border">
                  <div className="accordion_head">
                    <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                    How can players enhance their gaming journey?
                  </div>
                  <div className="accordion_body">
                    <p>Players can elevate their gaming experience by making informed choices and mastering the art of the game. By selecting their preferred numbers and considering factors such as game security, they can showcase their skills and increase their chances of winning rewards.</p>
                  </div>
                </div>
                <div className="accordion_border">
                  <div className="accordion_head">
                    <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                    How does Xakpot differentiate itself from traditional casino platforms?
                  </div>
                  <div className="accordion_body">
                    <p>Xakpot revolutionizes the gaming experience by prioritizing skill, strategy, and personal preferences. Unlike traditional casinos, we empower players to choose and play with their favorite numbers, emphasizing the importance of informed decisions and gaming expertise.</p>
                  </div>
                </div>
               
              </div>
            </div>
          </section>

        </Frame>
      );
    }
}

export default Info

 
