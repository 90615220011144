import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <header>
          <div className="container text-center">
            <div className="logo">
                <a href="/">
                  <img src="img/logo.png" alt="" />
                </a>
            </div>
          </div>
        </header>
      );
    }
  }

  export default Header;