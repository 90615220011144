import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter,Link,Route} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';
import moment from 'moment';
import Xakpot from "../../contracts/Xakpot.json";
import getWeb3 from "../../getWeb3";
import Modal from 'react-bootstrap/Modal';

import Web3Modal from "web3modal";
import Web3 from "web3";

import WalletConnectProvider from "@walletconnect/web3-provider";
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed.binance.org/'
      },
      network: 'binance',
    }
  }
};

const initialState = {
  loading:false,
  is_connected:false,
  web3: null,
  accounts: null,
  contract: null,
  contractAddress: null,
  contractInfo: {
    contractAddress:'-',
    contractAddressLinkto: null
  },
  userInfo:{
    YourAddress: null,
    YourAddressfull:null,
    withdrawan:0,
    isRegisterd:false,
    userType:'',
    stockiest:''
  },
  sponsor_address:'',
  amountDeposit:'',
  startTime:null,
  totalUser:0,
  register_modal_show:false,
  min_deposit:10,
  lastDistribution:Math.floor(Date.now() / 1000),
  current_timestamp: Math.floor(Date.now() / 1000),
  game_time_interval:60*60,
  orderInfos:[],
  current_game_id:1,
  tokenPriceUSD:1,
  UsdToBnb:0,
  user_type:0,
  player_address:'',
  amountDistributor:'',
  getDistributorData:[],
  getDistributorAddressLength:0,
  distributor_list:[],
  selected_number:'',
  selected_distributor:'',
  player_buy_amount:10,
  getUserData:[],
  getUsergameIdLength:0,
  refund_button_show:true,
  user_history:[],
  distributor_history:[],
  getCommison:0,
  refund_time:5*60*1000,
  buy_qty:1,
  buy_modal_show:false,
  last_winning_no:'',
  selected_d_n_balance:0,
  gameData:[],
  isSwap:false,
  inputOne:0,
  UsdToBnb:0,
  tokenAmount:0,
  usd_amount:0,
};

const default_user_type = ['player','distributor','stockiest'];
const default_lottery_numbers = [0,1,2,3,4,5,6,7,8,9];

class Swap extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:false,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        contractInfo: {
          contractAddress:'-',
          contractAddressLinkto: null
        },
        userInfo:{
          YourAddress: null,
          YourAddressfull:null,
          withdrawan:0,
          isRegisterd:false,
          userType:'',
          stockiest:''
        },
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        amountDeposit:'',
        startTime:null,
        totalUser:0,
        register_modal_show:false,
        min_deposit:10,
        lastDistribution:Math.floor(Date.now() / 1000),
        current_timestamp: Math.floor(Date.now() / 1000),
        game_time_interval:60*60,
        orderInfos:[],
        current_game_id:1,
        tokenPriceUSD:1,
        UsdToBnb:0,
        user_type:0,
        player_address:'',
        amountDistributor:'',
        getDistributorData:[],
        getDistributorAddressLength:0,
        distributor_list:[],
        selected_number:'',
        selected_distributor:'',
        player_buy_amount:10,
        getUserData:[],
        getUsergameIdLength:0,
        refund_button_show:true,
        user_history:[],
        distributor_history:[],
        getCommison:0,
        refund_time:5*60*1000,
        buy_qty:1,
        buy_modal_show:false,
        last_winning_no:'',
        selected_d_n_balance:0,
        gameData:[],
        isSwap:false,
        inputOne:0,
        UsdToBnb:0,
        tokenAmount:0,
        usd_amount:0,
      }
    }

    componentDidMount = async () => {
      try {
        let web3Modal = new Web3Modal({ cacheProvider: true, providerOptions });
        if (web3Modal.cachedProvider) {
          let web3ModalInstance = await web3Modal.connect();
          await web3ModalInstance.enable();
          let web3 = new Web3(web3ModalInstance);
          let accounts = await web3.eth.getAccounts();
  
          // Subscribe to accounts change
          web3ModalInstance.on("accountsChanged", (accounts) => {
            console.log(accounts);
            console.log(this.state.accounts);
            if(accounts.length){
              //accounts = accounts;
              this.setState({accounts:accounts[0]});
            }else{
              //this.disconnectFromWallet();
              //this.connectToWallet();
            }
            //this.disconnectFromWallet();
            this.connectToWallet();
          });
  
          // Subscribe to chainId change
          web3ModalInstance.on("chainChanged", (chainId) => {
            this.disconnectFromWallet();
            //this.connectToWallet();
          });
  
          // Subscribe to provider connection
          web3ModalInstance.on("connect", (info) => {            
            this.connectToWallet();
          });
  
          // Subscribe to provider disconnection
          web3ModalInstance.on("disconnect", (error) => {           
            this.disconnectFromWallet();
          });
  
          const networkId = await web3.eth.net.getId();
          //if(this.state.accounts){
          if(networkId !== 56) {
            this.props.enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
            this.setState({loading:false});
            return false;            
          }
          //}
          // Use web3 to get the user's accounts.
  
          if(this.state.accounts !== accounts[0]){
            // Get the contract instance.
            
            const deployedNetwork = Xakpot.networks;
            const instance = new web3.eth.Contract(
              Xakpot.abi,
              deployedNetwork && Xakpot.address,
            );
  
            let user_address = accounts[0];
  
            this.setState({loading:false, is_connected:true});
            this.setState({ web3, accounts:user_address, contract: instance, contractAddress:Xakpot.address}, this.fetchData);
          }
        }
  
      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false, loading:false});
        //console.error(error);
      }
    };

    connectToWallet = async (e) => {
      //e.preventDefault();
      //alert('kk');
      try {        
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
        // Use web3 to get the user's accounts.
        const accounts = await web3.eth.getAccounts();
  
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        if(networkId !== 56) {
          this.props.enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
          this.setState({loading:false});
          return false;
        }
         
        if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          
          this.setState({loading:true});
          const deployedNetwork = Xakpot.networks;
          const instance = new web3.eth.Contract(
            Xakpot.abi,
            deployedNetwork && Xakpot.address,
          );
  
          let user_address = accounts[0];
  
          this.setState({loading:false, is_connected:true});
          this.setState({ web3, accounts:user_address, contract: instance, contractAddress:Xakpot.address}, this.fetchData);
          this.props.enqueueSnackbar("Wallet Connected",{ variant: 'success' });
        }else{
          this.setState({loading:false, is_connected:true});
          this.props.enqueueSnackbar("Wallet Connected",{ variant: 'success' });
        }
      } catch (error) {
        // Catch any errors for any of the above operations.
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
        this.setState({networkMain:false});
        console.error(error);
        this.setState({loading:false});
      }      
    };
  
    disconnectFromWallet = async () => {
      try {
        this.setState({loading:true});
        let web3Modal = new Web3Modal({ cacheProvider: false, providerOptions });
        
        await web3Modal.clearCachedProvider();
  
        //await web3Modal.current.clearCachedProvider();
        //window.localStorage.clear();
        if(this.state.web3){
          this.props.enqueueSnackbar("Wallet disconnected",{ variant: 'success' });
        }
        
        this.setState(initialState);
        this.props.history.push('/');
      } catch (error) {
        // Catch any errors for any of the above operations.
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
        this.setState({networkMain:false});
        console.error(error);
        this.setState({loading:false});
      } 
    }
  
    fetchData= async()=>{
      const { accounts, contract } = this.state;

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      
      const contractAddress = this.state.contractAddress;
      const users = await contract.methods.users(accounts).call();
      if(!users.isRegisterd){
        this.setState({register_modal_show:true});
      }else{
        this.setState({register_modal_show:false});
      }      

      let getLatestPrice = await contract.methods.getLatestPrice().call();
      let UsdToBnb = 0;
      if(getLatestPrice){
        UsdToBnb = getLatestPrice/1e8;
      }
      console.log("usdPrice",UsdToBnb);

      this.setState({UsdToBnb});
      this.setState(({contractInfo})=>
        ({contractInfo : {...contractInfo, 
          contractAddress:contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length),
          contractAddressLinkto:contractAddress
        }})
      );      
  

    }

    

   

   
    doSwap = async(type) => {
      if(type=="XAKTOBNB"){
        let pool = await this.state.contract.methods.tokenPool().call();
        let balance = await this.state.contract.methods.tokenBalnceCheck(this.state.accounts).call()
        balance =balance/1e8;
        pool = parseFloat(pool)/1e8;
        if(parseFloat(balance)<parseFloat(this.state.tokenAmount)){
          this.props.enqueueSnackbar("Insufficient  Balance",{ variant: 'error' });
          return false;
        }
        if(pool<parseFloat(this.state.usd_amount)){
          this.props.enqueueSnackbar("No Funds Available",{ variant: 'error' });
          return false;
        }
        
      }
      if(type=="BNBTOXAK"){
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);
        let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
        if(parseFloat(balanceEthVal)<parseFloat(this.state.inputOne)){
          this.props.enqueueSnackbar("Insufficient  Balance",{ variant: 'error' });
          return false;
        }
      }
      try {
        this.setState({loading:true})
        if(type=="XAKTOBNB"){
          const data = await this.state.contract.methods.sellToken(parseInt(this.state.tokenAmount*1e8).toString()).send(
            {
              from: this.state.accounts,
              value:0
            }
          );

          if(data.status){
            this.setState({loading:false,tokenAmount:0,usd_amount:0,inputOne:0});
            this.props.enqueueSnackbar("Swap successfully! ",{ variant: 'success' })
            this.fetchData();
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
            //////////// transaction error ////////////
          }
        }else if(type=="BNBTOXAK"){
          let weiValue = await this.state.web3.utils.toWei(this.state.inputOne.toString(), 'ether');
          const data = await this.state.contract.methods.buyToken().send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );

          if(data.status){
            this.setState({loading:false,tokenAmount:0,usd_amount:0,inputOne:0});
            this.props.enqueueSnackbar("Swap successfully! ",{ variant: 'success' })
            this.fetchData();
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
            //////////// transaction error ////////////
          }
        }
    
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }else
        if(err.message.includes('Confirmation declined by user')){
          defaultError = err.message;
        }else
        if(err.message.includes('MetaMask Tx Signature')){
          defaultError = err.message;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }
   
  
   

 

    render() {
      let this2 = this;
   

      console.log(this.state.is_connected);
      
      return (
        <Frame withHeader={false} withFooter={false} isHome={true}>

        <header>
          <div className="container">
            <div className="header_box">
              <nav className="navbar navbar-expand-md">
                <div className="logo">
                    <a className="navbar-brand" href="/">
                      <img src="img/logo.png" alt="" />
                    </a>
                </div>
                <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-expanded="false">
                  <img src="img/menu.png" alt="" />
                </button>
              
                <div class="navbar-collapse collapse" id="collapsibleNavbar">
                  <ul className='navbar-nav mr-auto'>
                  
                  </ul>
                  <ul className="navbar-nav">
                  <li class="nav-item">
                      <Link  className="btn btn-round btn-primary wallet_btn btn-lg"to="/wallet">
                      <img src="/img/binary.png" /> Binary
                      </Link>  
                    </li>
                    <li  className="nav-item">
                      <Link className="btn btn-round btn-primary wallet_btn btn-lg" to="/swap">
                      <img src="/img/swap.png" /> Swap
                      </Link>  
                    </li>
                    <li className="nav-item">
                      <Link className="btn btn-round btn-primary wallet_btn btn-lg" onClick={()=>this.validDater()}>
                      <img src="/img/register.png" /> Register
                      </Link>
                    </li>
                  {this.state.is_connected ? (
                      <li className="nav-item">
                      <button type="button" disabled={this.state.loading} onClick={()=>this.disconnectFromWallet()} className="btn btn-round btn-primary wallet_btn btn-lg">
                        <img src='/img/c-wallet.png'></img>
                        <span>Wallet Disconnect</span>
                      </button>
                      </li>
                    ):(
                      <li className="nav-item">
                      <button type="button" className="btn btn-round wallet_btn btn-primary btn-lg" disabled={this.state.loading} onClick={()=>this.connectToWallet()}>
                        <img src='/img/c-wallet.png'></img>
                        <span>Wallet Connect</span>
                      </button>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
              
            </div>
          </div>
        </header>

          {this.state.loading ? (
            <div className="loader-container">
              <div className="wrapper">
                <div className="left"></div>
                <div className="right"></div>
              </div>
            </div>
          ) : (null)}

 

          <section className='banner_bg position-relative'>
          {!this.state.is_connected?(
             <div className='container'>
             <div className='alert alert-warning'>
               Please Connect Wallet to <strong>Fetch Price!</strong>
             </div>
           </div>
          ):(null)}
         
          <div className="heading-text mb-4 text-center"><h2>Swap</h2></div>
        
            {this.state.isSwap?(
              <div className="container">
              <div className='swap_container'>
                <div className='shadow_box p-4'>
                  <div className='mb-4'>
                    <div className='swap_coin'>
                      <span>XAK</span>
                    </div>
                    <div className='position-relative'>
                      <input className='form-control' type="number" placeholder='0.00'
                        value={parseFloat(this.state.tokenAmount) || ''}
                        onChange={(e)=>this.setState({tokenAmount:e.target.value},async()=>{
                          let usd_amount = await this.state.contract.methods.calculateUSdRecived(e.target.value*1e8).call();
                          console.log(usd_amount);
                          usd_amount = usd_amount/1e8;
                          let inputOne =  usd_amount/this.state.UsdToBnb;
                          this.setState({usd_amount:usd_amount,inputOne});
                        })} />
                      <span className="convert_text">{parseFloat(this.state.usd_amount).toFixed(2)} USD</span>
                    </div>
                  </div>
                  <div className='text-center'>
                  <button className="swaping_box"><img src="img/swap.png" alt="" onClick={()=>{this.setState({isSwap:false,tokenAmount:'',usd_amount:0,inputOne:0})}} /></button>
                  </div>
                  <div className='mb-4'>
                    <div className='swap_coin'>
                      <span>BNB</span>
                    </div>
                    <div className='position-relative'>
                      <input className='form-control' type="number" placeholder='0.00' value={parseFloat(this.state.inputOne).toFixed(4)} />
                      <span className="convert_text">{parseFloat(this.state.usd_amount).toFixed(2)} USD</span>
                    </div>
                  </div>
                  <button className="btn btn-round btn-primary btn-block btn-lg" disabled={this.state.loading} onClick={()=>this.doSwap('XAKTOBNB')}>Swap</button>
                
                </div>
              </div>
            </div>
            ):(
              <div className="container">
              <div className='swap_container'>
                <div className='shadow_box p-4'>
                  <div className='mb-4'>
                    <div className='swap_coin'>
                      <span>BNB</span>
                    </div>
                    <div className='position-relative'>
                      <input className='form-control' type="number" placeholder='0.00'
                       value={this.state.inputOne || ''}
                       onChange={(e)=>this.setState({inputOne:e.target.value},async()=>{

                         let usd_amount =  parseFloat(e.target?.value||0)*parseFloat(this.state.UsdToBnb);
                         console.log(usd_amount*1e8);
                         let tokenAmount = await this.state.contract.methods.calculateTokenRecived(parseInt(usd_amount*1e8)).call();
                         tokenAmount = parseFloat(tokenAmount/1e8).toFixed(2);
                         this.setState({tokenAmount:tokenAmount,usd_amount:usd_amount});
                       })} />
                      <span className="convert_text">{parseFloat(this.state.usd_amount).toFixed(2)} USD</span>
                    </div>
                  </div>
                  <div className='text-center'>
                    <button className="swaping_box"><img src="img/swap.png" alt="" onClick={()=>{this.setState({isSwap:true,tokenAmount:'',usd_amount:0,inputOne:0})}} /></button>
                  </div>
                  <div className='mb-4'>
                    <div className='swap_coin'>
                      <span>XAK</span>
                    </div>
                    <div className='position-relative'>
                      <input className='form-control' type="number" readOnly placeholder='0.00' value={parseFloat(this.state.tokenAmount)} />
                      <span className="convert_text">{parseFloat(this.state.usd_amount).toFixed(2)} USD</span>
                    </div>
                  </div>
                  <button className="btn btn-round btn-primary btn-block btn-lg" disabled={this.state.loading} onClick={()=>this.doSwap('BNBTOXAK')}>Swap</button>
                
                </div>
              </div>
            </div>
            )}
           
          </section>

         

        

        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    },
  }
  
  export default withRouter(withStyles(useStyles)(withSnackbar(Swap)));
  //export default Swap;