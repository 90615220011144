import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter,Link,Route} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';
import moment from 'moment';
import Xakpot from "../../contracts/Xakpot.json";
import getWeb3 from "../../getWeb3";
import Modal from 'react-bootstrap/Modal';
import {communityDetailsApi, packageDetailsApi, compoundSubscriptionApi, addressDetailApi, dataDetailsApi, withdrawApi } from '../../utils';

import Web3Modal from "web3modal";
import Web3 from "web3";

import WalletConnectProvider from "@walletconnect/web3-provider";
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed.binance.org/'
      },
      network: 'binance',
    }
  }
};

const initialState = {
  loading:true,
  is_connected:false,
  web3: null,
  accounts: null,
  contract: null,
  contractAddress: null,
  contractAddress_Short:'-',
  sponsor_address:'',
  userInfo:{},
  networkMain:false,
  YourAddress: '-',
  YourAddressfull: null,
  amountBNB:null,
  user_referer:'-',
  balance:null,
  adminFee:null,
  getDepositLength:0,
  withdrawl_amount:'',
  globalInfo:{
    systemUserCount: 0,
    systemInvest:0,
    systemWithdraw:0
  },
  userDetails:{
    userDeposit:0,
    userWithdraw:0,
    userReferredBy:'-',
    userReferredByFull:'-',
    binaryIncome:0,
    directIncome:0,
    binarySponsorIncome:0,
    walletBalance:0,
    businessForNextRank:0
  },
  currentProfileRank:{},
  nextProfileRank:{},
  communityDetails:{
    downline_list:[]
  },
  allPackages:{},
  selected_package:false,
  UsdToBnb:0,
  range_usd_price:0,
  invest_status:false,
  address_detail:null,
  subscriptionList:null,
  withdrawList:null,
  incomeList:null,
  sponsorTree:null,
  binaryPayoutLog:null,
  address_block_list:[],
  is_sync_show:false,
  disable_address:'0x2E3F39422a9494f4a8DAE547bce3F4A34a42E3541',
  package: [],
  // package: [1,2,3,4,5,6],
  packageIndex:0,
  // admin_new:null,
  userSide:0,
  // userSide:'L',
  minimumWithdraw:10,
  maximumWithdraw:100,
  currentSubAmount:0,
  currentRealSubAmount:0,
  currentTrialSubAmount:0,
  DirectList:{},
  register_modal_show:false,
  selected_type:0,
  packages:[],
  trialPackages:[],
  realPackages:[],
  typeWiseInvestment:0,
  isStockiest:true,
};

const default_user_type = ['player','distributor','stockiest'];
const default_lottery_numbers = [0,1,2,3,4,5,6,7,8,9];

class Wallet extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        is_connected:false,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        contractAddress_Short:'-',
        sponsor_address:'',
        userInfo:{},
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
        amountBNB:null,
        user_referer:'-',
        balance:null,
        adminFee:null,
        getDepositLength:0,
        withdrawl_amount:'',
        globalInfo:{
          systemUserCount: 0,
          systemInvest:0,
          systemWithdraw:0
        },
        userDetails:{
          userDeposit:0,
          userWithdraw:0,
          userReferredBy:'-',
          userReferredByFull:'-',
          binaryIncome:0,
          directIncome:0,
          binarySponsorIncome:0,
          walletBalance:0,
          businessForNextRank:0
        },
        currentProfileRank:{},
        nextProfileRank:{},
        communityDetails:{
          downline_list:[]
        },
        allPackages:{},
        selected_package:false,
        UsdToBnb:0,
        range_usd_price:0,
        invest_status:false,
        address_detail:null,
        subscriptionList:null,
        withdrawList:null,
        incomeList:null,
        sponsorTree:null,
        binaryPayoutLog:null,
        address_block_list:[],
        is_sync_show:false,
        disable_address:'0x2E3F39422a9494f4a8DAE547bce3F4A34a42E3541',
        package: [],
        // package: [1,2,3,4,5,6],
        packageIndex:0,
        // admin_new:null,
        userSide:0,
        // userSide:'L',
        minimumWithdraw:10,
        maximumWithdraw:100,
        currentSubAmount:0,
        currentRealSubAmount:0,
        currentTrialSubAmount:0,
        DirectList:{},
        register_modal_show:false,
        selected_type:0,
        packages:[],
        trialPackages:[],
        realPackages:[],
        typeWiseInvestment:0,
        isStockiest:false,
      }
    }

    componentDidMount = async () => {
      try {
        let web3Modal = new Web3Modal({ cacheProvider: true, providerOptions });
        if (web3Modal.cachedProvider) {
          let web3ModalInstance = await web3Modal.connect();
          await web3ModalInstance.enable();
          let web3 = new Web3(web3ModalInstance);
          let accounts = await web3.eth.getAccounts();
  
          // Subscribe to accounts change
          web3ModalInstance.on("accountsChanged", (accounts) => {
            console.log(accounts);
            console.log(this.state.accounts);
            if(accounts.length){
              //accounts = accounts;
              this.setState({accounts:accounts[0]});
            }else{
              //this.disconnectFromWallet();
              //this.connectToWallet();
            }
            //this.disconnectFromWallet();
            this.connectToWallet();
          });
  
          // Subscribe to chainId change
          web3ModalInstance.on("chainChanged", (chainId) => {
            this.disconnectFromWallet();
            //this.connectToWallet();
          });
  
          // Subscribe to provider connection
          web3ModalInstance.on("connect", (info) => {            
            this.connectToWallet();
          });
  
          // Subscribe to provider disconnection
          web3ModalInstance.on("disconnect", (error) => {           
            this.disconnectFromWallet();
          });
  
          const networkId = await web3.eth.net.getId();
          //if(this.state.accounts){
          if(networkId !== 56) {
            this.props.enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
            this.setState({loading:false});
            return false;            
          }
          //}
          // Use web3 to get the user's accounts.
  
          if(this.state.accounts !== accounts[0]){
            // Get the contract instance.
            
            const deployedNetwork = Xakpot.networks;
            const instance = new web3.eth.Contract(
              Xakpot.abi,
              deployedNetwork && Xakpot.address,
            );
  
            let user_address = accounts[0];
  
            this.setState({loading:false, is_connected:true});
            this.setState({ web3, accounts:user_address, contract: instance, contractAddress:Xakpot.address}, this.fetchData);
          }
        }
  
      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false, loading:false});
        //console.error(error);
      }
    };

    connectToWallet = async (e) => {
      //e.preventDefault();
      //alert('kk');
      try {        
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
        // Use web3 to get the user's accounts.
        const accounts = await web3.eth.getAccounts();
  
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        if(networkId !== 56) {
          this.props.enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
          this.setState({loading:false});
          return false;
        }
         
        if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          
          this.setState({loading:true});
          const deployedNetwork = Xakpot.networks;
          const instance = new web3.eth.Contract(
            Xakpot.abi,
            deployedNetwork && Xakpot.address,
          );
  
          let user_address = accounts[0];
  
          this.setState({loading:false, is_connected:true});
          this.setState({ web3, accounts:user_address, contract: instance, contractAddress:Xakpot.address}, this.fetchData);
          this.props.enqueueSnackbar("Wallet Connected",{ variant: 'success' });
        }else{
          this.setState({loading:false, is_connected:true});
          this.props.enqueueSnackbar("Wallet Connected",{ variant: 'success' });
        }
      } catch (error) {
        // Catch any errors for any of the above operations.
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
        this.setState({networkMain:false});
        console.error(error);
        this.setState({loading:false});
      }      
    };
  
    disconnectFromWallet = async () => {
      try {
        this.setState({loading:true});
        let web3Modal = new Web3Modal({ cacheProvider: false, providerOptions });
        
        await web3Modal.clearCachedProvider();
  
        //await web3Modal.current.clearCachedProvider();
        //window.localStorage.clear();
        if(this.state.web3){
          this.props.enqueueSnackbar("Wallet disconnected",{ variant: 'success' });
        }
        
        this.setState(initialState);
        this.props.history.push('/');
      } catch (error) {
        // Catch any errors for any of the above operations.
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
        this.setState({networkMain:false});
        console.error(error);
        this.setState({loading:false});
      } 
    }
  
    fetchData = async() => {
      const { accounts, contract, contractAddress } = this.state;
      //accounts = '0x6fF605Bb53a862dC731e3112594Cbc7720A18e6c';
      //accounts = '0xBa498a4F5d11d6de8a20533EA94A9C512AD2e28D';
      //accounts = '0x41dC82912494cAFc2854dfe2201CCFFEA5B949fd';

      //console.log(this.state.contractAddress);
      let contractAddress_Short = contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length);
      let YourAddress = accounts.slice(0, 2) + '...' + accounts.slice(accounts.length-2, accounts.length);
      let YourAddressfull = accounts;
      //console.log(accounts);
      //let userInfo = await contract.methods.getUserInfo(accounts).call();
      let userInfo = await contract.methods.users(accounts).call();
      //console.log(userInfo);
      let isStockiest = userInfo.isstockiest;
      console.log(isStockiest);

     
      let user_referer = '-';
      if(userInfo. sponsor && !/^0x0+$/.test(userInfo.sponsor)){
        user_referer = userInfo.sponsor;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      }

      this.setState({ 
        contractAddress_Short,
        YourAddress,
        YourAddressfull,
        userInfo,
        user_referer,
        isStockiest,
      },async()=>{
        this.packageDetailsApi();
        this.communityDetailsApi();        
        this.dataDetailsApi();        
      });     
    }

    paginate = (
      totalItems,
      currentPage = 1,
      pageSize = 10,
      maxPages = 10
    ) => {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
  
      // ensure current page isn't out of range
      if (currentPage < 1) {
          currentPage = 1;
      } else if (currentPage > totalPages) {
          currentPage = totalPages;
      }
  
      let startPage, endPage;
      if (totalPages <= maxPages) {
          // total pages less than max so show all pages
          startPage = 1;
          endPage = totalPages;
      } else {
          // total pages more than max so calculate start and end pages
          let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
          let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
          if (currentPage <= maxPagesBeforeCurrentPage) {
              // current page near the start
              startPage = 1;
              endPage = maxPages;
          } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
              // current page near the end
              startPage = totalPages - maxPages + 1;
              endPage = totalPages;
          } else {
              // current page somewhere in the middle
              startPage = currentPage - maxPagesBeforeCurrentPage;
              endPage = currentPage + maxPagesAfterCurrentPage;
          }
      }
  
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  
      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  
      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
    }
  
    getPaginationData = async(url,page,type,current_page,last_page) => {
      if(!url && !page){
        return true;
      }
      this.setState({loading:true});
      this.dataDetailsApi(url,page,type);
    }

    communityDetailsApi = async()=>{
      if(!this.state.accounts){
        return false;
      }
      const data = await communityDetailsApi(this.state.accounts);
      console.log("data",data);
      //const data = await communityDetailsApi('0x6fF605Bb53a862dC731e3112594Cbc7720A18e6c');
      //const data = await communityDetailsApi('TWC8GqmGNkW8m5gYBz1bYKx58qxM8qzdzf');
      //const data = await communityDetailsApi('TEwD1YadPF1ef388xbPwuCWFNGLFDXcJYp');
      //const data = await communityDetailsApi('0xC5d99c8B7E70a38423E7F4BA709d63686928537C');
      //const data = await communityDetailsApi('0x51ac3516b5964df862ef1F7b212313bF32aA8e35');
      //console.log('communityDetailsApi',data);      
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo,
            systemUserCount: data.data.systemUserCount,
            systemInvest:data.data.systemInvest,
            systemWithdraw:data.data.systemWithdraw
          }})
        );
        let userReferredBy = data.data.userReferredBy;
        if(userReferredBy == 'admin'){
          
        }else{
          userReferredBy = data.data.userReferredBy.slice(0, 5) + '...' +data.data.userReferredBy.slice(data.data.userReferredBy.length-5, data.data.userReferredBy.length);
        }
        this.setState(({userDetails})=>
          ({userDetails : {...userDetails,
            userDeposit:data.data.userDeposit,
            userWithdraw:data.data.userWithdraw,
            userReferredByFull:data.data.userReferredBy ? data.data.userReferredBy : null,
            userReferredBy:data.data.userReferredBy ? userReferredBy : null,
            binaryIncome:parseFloat(data.data.binaryIncome).toFixed(2),
            directIncome:parseFloat(data.data.directIncome).toFixed(2),
            binarySponsorIncome:parseFloat(data.data.binarySponsorIncome).toFixed(2),
            walletBalance:parseFloat(data.data.walletBalance).toFixed(2),
            businessForNextRank:parseFloat(data.data.businessForNextRank).toFixed(2),
            totalEnergy:data.data.userProfile.energy,
            remaining_energy:data.data.userProfile.remaining_energy,
            energy_validity:data.data.userProfile.energy_validity,

          }})
        );
        
        this.setState({
          currentProfileRank:data.data.currentProfileRank,
          minimumWithdraw:parseFloat(data.data.minimumWithdraw).toFixed(2),
          maximumWithdraw:parseFloat(data.data.maximumWithdraw).toFixed(2),
          currentSubAmount:data.data.currentSubAmount?parseFloat(data.data.currentSubAmount).toFixed(2):0,
          currentRealSubAmount:data.data.currentRealSubAmount?parseFloat(data.data.currentRealSubAmount).toFixed(2):0,
          currentTrialSubAmount:data.data.currentTrialSubAmount?parseFloat(data.data.currentTrialSubAmount).toFixed(2):0,
          nextProfileRank:data.data.nextProfileRank
        });
      }else{
        
        this.setState({loading:false, invest_status:false});
      }
    }

    packageDetailsApi = async()=>{
      
      const data = await packageDetailsApi();
      console.log(data);
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo,
            systemUserCount: data.data.systemUserCount,
            systemInvest:data.data.systemInvest,
            systemWithdraw:data.data.systemWithdraw
          }})
        );
      
        this.setState({
          package:data.package,
          packages:data.packages,
          trialPackages:data.trialPackages,
          realPackages:data.realPackages
        });

      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    addressDetailApi = async()=>{
      if(!this.state.accounts){
        return false;
      }
      const data = await addressDetailApi(this.state.accounts);
      //const data = await addressDetailApi('xcdgvxdgesdfdsfwsfr33');
      //console.log(data.data.details);
      if(data.status){
        this.setState({
          address_detail:data.data.details,
          loading:false});
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    dataDetailsApi = async(url=null,page=null,type=null)=>{
      if(!this.state.accounts){
        return false;
      }
      //const data = await dataDetailsApi('admin',url,page,type);
      const data = await dataDetailsApi(this.state.accounts,url,page,type);
      //const data = await dataDetailsApi('0xa3c86f5dee63aef205e52bc3b00151634dcd7594',url,page,type);
      //console.log('dataDetailsApi',data);
      if(data.status){
        // let totalIncome = parseFloat(data.data.uplineIncome) +
        // parseFloat(data.data.boardIncome) + parseFloat(data.data.binaryIncome);
        // this.setState(({userDetails})=>
        //   ({userDetails : {...userDetails,
        //     binaryIncome:parseFloat(data.data.binaryIncome).toFixed(2),
        //     directIncome:parseFloat(data.data.directIncome).toFixed(2),
        //     binarySponsorIncome:parseFloat(data.data.binarySponsorIncome).toFixed(2),
        //     walletBalance:parseFloat(data.data.walletBalance).toFixed(2),
        //     businessForNextRank:parseFloat(data.data.businessForNextRank).toFixed(2)
        //   }})
        // );
  
        let subscriptionList = data.data.subscriptionList;
        let subscriptionListPagination = this.paginate(subscriptionList.total,subscriptionList.current_page,subscriptionList.per_page,10);
        subscriptionList.pages = subscriptionListPagination.pages;
  
        let withdrawList = data.data.withdrawList;
        let withdrawListPagination = this.paginate(withdrawList.total,withdrawList.current_page,withdrawList.per_page,10);
        withdrawList.pages = withdrawListPagination.pages;
  
        let incomeList = data.data.incomes;
        let incomeListPagination = this.paginate(incomeList.total,incomeList.current_page,incomeList.per_page,10);
        incomeList.pages = incomeListPagination.pages;
  
        let binaryPayoutLog = data.data.binaryPayoutLog;
        let binaryPayoutLogPagination = this.paginate(binaryPayoutLog.total,binaryPayoutLog.current_page,binaryPayoutLog.per_page,10);
        binaryPayoutLog.pages = binaryPayoutLogPagination.pages;

        let DirectList = data.data.directList;
        DirectList= Object.values(DirectList);
        
        this.setState({
          subscriptionList:data.data.subscriptionList,
          withdrawList:data.data.withdrawList,
          incomeList:data.data.incomes,
          sponsorTree:data.data.sponsorTree,
          binaryPayoutLog:data.data.binaryPayoutLog,
          DirectList,
          loading:false        
        });
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    

   

   
    doSwap = async(type) => {
      if(type=="XAKTOBNB"){
        let pool = await this.state.contract.methods.tokenPool().call();
        let balance = await this.state.contract.methods.tokenBalnceCheck(this.state.accounts).call()
        balance =balance/1e8;
        pool = parseFloat(pool)/1e8;
        if(parseFloat(balance)<parseFloat(this.state.tokenAmount)){
          this.props.enqueueSnackbar("Insufficient  Balance",{ variant: 'error' });
          return false;
        }
        if(pool<parseFloat(this.state.usd_amount)){
          this.props.enqueueSnackbar("No Funds Available",{ variant: 'error' });
          return false;
        }
        
      }
      if(type=="BNBTOXAK"){
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);
        let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
        if(parseFloat(balanceEthVal)<parseFloat(this.state.inputOne)){
          this.props.enqueueSnackbar("Insufficient  Balance",{ variant: 'error' });
          return false;
        }
      }
      try {
        this.setState({loading:true})
        if(type=="XAKTOBNB"){
          const data = await this.state.contract.methods.sellToken(parseInt(this.state.tokenAmount*1e8).toString()).send(
            {
              from: this.state.accounts,
              value:0
            }
          );

          if(data.status){
            this.setState({loading:false,tokenAmount:0,usd_amount:0,inputOne:0});
            this.props.enqueueSnackbar("Swap successfully! ",{ variant: 'success' })
            this.fetchData();
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
            //////////// transaction error ////////////
          }
        }else if(type=="BNBTOXAK"){
          let weiValue = await this.state.web3.utils.toWei(this.state.inputOne.toString(), 'ether');
          const data = await this.state.contract.methods.buyToken().send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );

          if(data.status){
            this.setState({loading:false,tokenAmount:0,usd_amount:0,inputOne:0});
            this.props.enqueueSnackbar("Swap successfully! ",{ variant: 'success' })
            this.fetchData();
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
            //////////// transaction error ////////////
          }
        }
    
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }else
        if(err.message.includes('Confirmation declined by user')){
          defaultError = err.message;
        }else
        if(err.message.includes('MetaMask Tx Signature')){
          defaultError = err.message;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }
   
  
   

 

    render() {
      let this2 = this;
   

      console.log(this.state.is_connected);
      
      return (
        <Frame withHeader={false} withFooter={false} isHome={true}>

        <header>
          <div className="container">
            <div className="header_box">
              <nav className="navbar navbar-expand-md">
                <div className="logo">
                    <a className="navbar-brand" href="/">
                      <img src="img/logo.png" alt="" />
                    </a>
                </div>
                <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-expanded="false">
                  <img src="img/menu.png" alt="" />
                </button>
              
                <div class="navbar-collapse collapse" id="collapsibleNavbar">
                  <ul className='navbar-nav mr-auto'>
                  
                  </ul>
                  <ul className="navbar-nav">
                  <li class="nav-item">
                      <Link  className="btn btn-round btn-primary wallet_btn btn-lg"to="/wallet">
                      <img src="/img/binary.png" /> Binary
                      </Link>  
                    </li>
                    <li  className="nav-item">
                      <Link className="btn btn-round btn-primary wallet_btn btn-lg" to="/swap">
                      <img src="/img/swap.png" /> Swap
                      </Link>  
                    </li>
                    <li className="nav-item">
                      <Link className="btn btn-round btn-primary wallet_btn btn-lg" onClick={()=>this.validDater()}>
                      <img src="/img/register.png" /> Register
                      </Link>
                    </li>
                  {this.state.is_connected ? (
                      <li className="nav-item">
                      <button type="button" disabled={this.state.loading} onClick={()=>this.disconnectFromWallet()} className="btn btn-round btn-primary wallet_btn btn-lg">
                        <img src='/img/c-wallet.png'></img>
                        <span>Wallet Disconnect</span>
                      </button>
                      </li>
                    ):(
                      <li className="nav-item">
                      <button type="button" className="btn btn-round wallet_btn btn-primary btn-lg" disabled={this.state.loading} onClick={()=>this.connectToWallet()}>
                        <img src='/img/c-wallet.png'></img>
                        <span>Wallet Connect</span>
                      </button>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
              
            </div>
          </div>
        </header>

          {this.state.loading ? (
            <div className="loader-container">
              <div className="wrapper">
                <div className="left"></div>
                <div className="right"></div>
              </div>
            </div>
          ) : (null)}


 
          <div className='header_padding'></div> 
          {this.state.isStockiest?(
            <>
         
            <section className='pt-5 pb-3'>
            <div className="shape2 left_shape"></div>
            <div className="shape right_shape"></div>
            <div className='container'>
              <div className="row">
                <div className="col-md-4 col-lg-3">
                  <div className="total_deposit">
                    <h4>Total Deposit</h4>
                    <h2>{parseFloat(this.state.userDetails.userDeposit||0).toFixed(2)} USD</h2>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="total_deposit">
                    <h4>Total Withdraw</h4>
                    <h2>{parseFloat(this.state.userDetails.userWithdraw||0).toFixed(2)} USD</h2>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="total_deposit">
                    <h4>Binary Income</h4>
                    <h2>{parseFloat(this.state.userDetails.binaryIncome||0).toFixed(2)} USD</h2>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="total_deposit">
                    <h4>Direct Income</h4>
                    <h2>{parseFloat(this.state.userDetails.directIncome||0).toFixed(2)} USD</h2>
                  </div>
                </div>
                {/* <div className="col-md-4 col-lg-3">
                  <div className="total_deposit">
                    <h4>Total Energy</h4>
                    <h2>0</h2>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="total_deposit">
                    <h4>Remaining Energy</h4>
                    <h2>0</h2>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="total_deposit">
                    <h4>Energy validity</h4>
                    <h2>000-00-00</h2>
                  </div>
                </div> */}
               
              </div>
            </div>
            {/* <div className='container mt-3'>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                <div className="rank_box">
                    <h4>Current Rank</h4>
                    <div className="rank_box_text">
                        <h5>Name</h5>
                        <p>{this.state.currentProfileRank?.name || '---'}</p>
                    </div>
                    
                    <div className="rank_box_text">
                        <h5>Binary <span>Percentage</span></h5>
                        <p>{this.state.currentProfileRank?.binary_percentage || '---'}</p>
                    </div>
                    <div className="rank_box_text">
                        <h5>Matching Binary<span> Percentage</span></h5>
                        <p>{this.state.currentProfileRank?.binary_sponsor_percentage || '---'}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="rank_box">
                    <h4>Next Rank</h4>
                    <div className="rank_box_text">
                        <h5>Name</h5>
                        <p>{this.state.nextProfileRank?.name || '---'}</p>
                    </div>
                    <div className="rank_box_text">
                        <h5>Binary <span>Percentage</span></h5>
                        <p>{this.state.nextProfileRank?.binary_percentage || '---'}</p>
                    </div>
                    <div className="rank_box_text">
                        <h5>Matching Binary<span> Percentage</span></h5>
                        <p>{this.state.nextProfileRank?.binary_sponsor_percentage || '---'}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </section>
          <hr className="big" />
          <section className="pt-4 pb-5 position-relative">
           
            <div className="container">
            <div className="shadow_box mb-0 p-4">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" data-toggle="tab" href="#table_tab1" role="tab" aria-selected="true">Subscription History</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-toggle="tab" href="#table_tab2" role="tab" aria-selected="false">Income List</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-toggle="tab" href="#table_tab3" role="tab" aria-selected="false">Withdraw List</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-toggle="tab" href="#table_tab4" role="tab" aria-selected="false">Binary Payout Log</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-toggle="tab" href="#table_tab5" role="tab" aria-selected="false">Directs Data</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade active show" id="table_tab1" role="tabpanel">
                    <div className="table_box">
                      <div className="table-responsive dark_table">
                        <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Price</th>
                              <th>Amount Paid</th>
                              {/* <th>ROI Percentage</th> */}
                              <th>Activation Date</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.subscriptionList ? (
                                this.state.subscriptionList.data.length>0 ? (
                                  this.state.subscriptionList.data.map(function(val, index){                        
                                    return (
                                      <tr key={`subs${index}`}>
                                        <td>{parseFloat(val.price).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.amount_paid).toFixed(4)} USD</td>
                                        {/* <td>{parseFloat(val.daily_return_percentage).toFixed(2)}%</td> */}
                                        <td>{val.created_at}</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="3" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                                ) : (
                                  <tr>
                                    <td colSpan="3" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                              )}
                            </tbody>
                        </table>
                        {this.state.subscriptionList ? (
                          <div className="text-center">
                            <ul className="table_pagination mb-0">
                              <li  style={{cursor:this.state.subscriptionList.prev_page_url?'pointer':'initial'}}>
                                <a onClick={()=>this.getPaginationData(this.state.subscriptionList.prev_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                              </li>
                              {this.state.subscriptionList.pages.length>0 ? (
                                this.state.subscriptionList.pages.map(function(val, index){
                                  let className = "";
                                  if(val == this2.state.subscriptionList.current_page){
                                    className = "active";
                                  }
                                  return (
                                    <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.subscriptionList.current_page?'pointer':'initial'}}>
                                      <a onClick={()=>this2.getPaginationData(null,'subs_page',val,null,null)} >{val}</a>
                                    </li>
                                  )
                                })
                              ):(null)}
                              <li style={{cursor:this.state.subscriptionList.next_page_url?'pointer':'initial'}}>
                                <a onClick={()=>this.getPaginationData(this.state.subscriptionList.next_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                              </li>
                            </ul>
                          </div>
                        ):(null)}
                      </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="table_tab2" role="tabpanel">
                    <div className="table_box">
                        <div className="table-responsive dark_table">
                          <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Overpay Amount</th>
                              <th>Source</th>
                              <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.incomeList ? (
                                this.state.incomeList.data.length>0 ? (
                                  this.state.incomeList.data.map(function(val, index){                        
                                    return (
                                      <tr key={`income${index}`}>
                                        <td>{parseFloat(val.amount).toFixed(4)} {val.currency}</td>
                                        <td>{parseFloat(val.overpay_amount).toFixed(4)} {val.currency}</td>
                                        <td>{val.source}</td>
                                        <td>{val.created_at}</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {this.state.incomeList ? (
                            <div className="text-center">
                              <ul className="table_pagination mb-0">
                                <li  style={{cursor:this.state.incomeList.prev_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.incomeList.prev_page_url,null,null,this.state.incomeList.current_page,this.state.incomeList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                                </li>
                                {this.state.incomeList.pages.length>0 ? (
                                  this.state.incomeList.pages.map(function(val, index){
                                    let className = "";
                                    if(val == this2.state.incomeList.current_page){
                                      className = "active";
                                    }
                                    return (
                                      <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.incomeList.current_page?'pointer':'initial'}}>
                                        <a onClick={()=>this2.getPaginationData(null,'income_page',val,null,null)} >{val}</a>
                                      </li>
                                    )
                                  })
                                ):(null)}
                                <li style={{cursor:this.state.incomeList.next_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.incomeList.next_page_url,null,null,this.state.incomeList.current_page,this.state.incomeList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                                </li>
                              </ul>
                            </div>
                          ):(null)}
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="table_tab3" role="tabpanel">
                    <div className="table_box">
                        <div className="table-responsive dark_table">
                          <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Amount From</th>
                              <th>Amount To</th>
                              <th>Remarks</th>
                              <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.withdrawList ? (
                                this.state.withdrawList.data.length>0 ? (
                                  this.state.withdrawList.data.map(function(val, index){                        
                                    return (
                                      <tr key={`income${index}`}>
                                        <td>{parseFloat(val.amount_from).toFixed(4)} {val.coin_type}</td>
                                        <td>{parseFloat(val.amount_to).toFixed(4)} {val.coin_type}</td>
                                        <td>{val.remarks}</td>
                                        <td>{val.created_at}</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {this.state.withdrawList ? (
                            <div className="text-center">
                              <ul className="table_pagination mb-0">
                                <li  style={{cursor:this.state.withdrawList.prev_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.withdrawList.prev_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                                </li>
                                {this.state.withdrawList.pages.length>0 ? (
                                  this.state.withdrawList.pages.map(function(val, index){
                                    let className = "";
                                    if(val == this2.state.withdrawList.current_page){
                                      className = "active";
                                    }
                                    return (
                                      <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.withdrawList.current_page?'pointer':'initial'}}>
                                        <a onClick={()=>this2.getPaginationData(null,'withdraw_page',val,null,null)} >{val}</a>
                                      </li>
                                    )
                                  })
                                ):(null)}
                                <li style={{cursor:this.state.withdrawList.next_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.withdrawList.next_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                                </li>
                              </ul>
                            </div>
                          ):(null)}
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="table_tab4" role="tabpanel">
                    <div className="table_box">
                        <div className="table-responsive dark_table">
                          <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Left Business</th>
                              <th>Right Business</th>
                              <th>Total Payout</th>
                              <th>Commission</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.binaryPayoutLog ? (
                                this.state.binaryPayoutLog.data.length>0 ? (
                                  this.state.binaryPayoutLog.data.map(function(val, index){                        
                                    return (
                                      <tr key={`income${index}`}>
                                        <td>{parseFloat(val.left_business).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.right_business).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.total_payout).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.commission).toFixed(4)} USD</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {this.state.binaryPayoutLog ? (
                            <div className="text-center">
                              <ul className="table_pagination mb-0">
                                <li  style={{cursor:this.state.binaryPayoutLog.prev_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.prev_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i className="fa fa-angle-double-left"></i></a>
                                </li>
                                {this.state.binaryPayoutLog.pages.length>0 ? (
                                  this.state.binaryPayoutLog.pages.map(function(val, index){
                                    let className = "";
                                    if(val == this2.state.binaryPayoutLog.current_page){
                                      className = "active";
                                    }
                                    return (
                                      <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.binaryPayoutLog.current_page?'pointer':'initial'}}>
                                        <a onClick={()=>this2.getPaginationData(null,'binary_page',val,null,null)} >{val}</a>
                                      </li>
                                    )
                                  })
                                ):(null)}
                                <li style={{cursor:this.state.binaryPayoutLog.next_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.next_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                                </li>
                              </ul>
                            </div>
                          ):(null)}
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="table_tab5" role="tabpanel">
                    <div className="table_box">
                        <div className="table-responsive dark_table">
                          <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Address</th>
                              <th >Total Direct</th>
                              <th>Package Details</th>
                              <th>Current Rank</th>
                              <th><div style={{width:130}}>Matching Amount</div></th>
                              <th><div style={{width:130}}>Next Matching Amount</div></th>
                              <th>Leg</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.DirectList ? (
                                this.state.DirectList.length>0 ? (
                                  this.state.DirectList.map(function(val, index){                        
                                    return (
                                      <tr key={`direct${index}`}>
                                        <td>{val.address.slice(0, 5) + '.....' + val.address.slice(val.address.length-5, val.address.length)}</td>
                                        <td>{val.direct_count}</td>
                                        <td>{parseFloat(val.total_investment).toFixed(2)} USD</td>
                                        <td>{val.profile_rank}</td>
                                        <td>{parseFloat(val.current_matching_business).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.matching_business_required_for_next_rank).toFixed(4)} USD</td>
                                        <td>{val.leg}</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="7" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                    </div>
                    </div>
                </div>
              </div>

            </div>
          </section>
          </>
          ):( <section className="pt-5 pb-5">
            <div className="container">
              <div className="rank_box text-center">
                <h4 className="mb-2">You are not registered on our platform</h4>
                <h4 className="mb-4">To register Download our App now.</h4>
                <a href="#"><img className="playstore_img" src="img/playstore.png" alt="" /></a>
              </div>
            </div>
          </section>)} 
          

         
        

        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    },
  }
  
  export default withRouter(withStyles(useStyles)(withSnackbar(Wallet)));
