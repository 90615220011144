import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';
import moment from 'moment';
import Xakpot from "../../contracts/Xakpot.json";
import getWeb3 from "../../getWeb3";
import Modal from 'react-bootstrap/Modal';
import { addressRegister } from '../../utils';

import Web3Modal from "web3modal";
import Web3 from "web3";

import WalletConnectProvider from "@walletconnect/web3-provider";
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed.binance.org/'
      },
      network: 'binance',
    }
  }
};

const initialState = {
  loading:false,
  is_connected:false,
  web3: null,
  accounts: null,
  contract: null,
  contractAddress: null,
  contractInfo: {
    contractAddress:'-',
    contractAddressLinkto: null
  },
  userInfo:{
    YourAddress: null,
    YourAddressfull:null,
    withdrawan:0,
    isRegisterd:false,
    userType:'',
    stockiest:''
  },
  sponsor_address:'',
  amountDeposit:'',
  startTime:null,
  totalUser:0,
  register_modal_show:false,
  min_deposit:10,
  lastDistribution:Math.floor(Date.now() / 1000),
  current_timestamp: Math.floor(Date.now() / 1000),
  game_time_interval:60*60,
  orderInfos:[],
  current_game_id:1,
  tokenPriceUSD:1,
  UsdToBnb:0,
  user_type:0,
  player_address:'',
  amountDistributor:'',
  getDistributorData:[],
  getDistributorAddressLength:0,
  distributor_list:[],
  selected_number:'',
  selected_distributor:'',
  player_buy_amount:10,
  getUserData:[],
  getUsergameIdLength:0,
  refund_button_show:true,
  user_history:[],
  distributor_history:[],
  getCommison:0,
  refund_time:5*60*1000,
  buy_qty:1,
  buy_modal_show:false,
  last_winning_no:'',
  selected_d_n_balance:0,
  gameData:[],
  defaultStockiest:'',
  inputStockiest:'',
  maintence_modal_show:false,
  userSide:'0',
  type:'0',
  payAmount:99
};

const default_user_type = ['player','distributor','stockiest'];
const default_lottery_numbers = [0,1,2,3,4,5,6,7,8,9];

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:false,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        contractInfo: {
          contractAddress:'-',
          contractAddressLinkto: null
        },
        userInfo:{
          YourAddress: null,
          YourAddressfull:null,
          withdrawan:0,
          isRegisterd:false,
          userType:'',
          stockiest:''
        },
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        amountDeposit:'',
        startTime:null,
        totalUser:0,
        register_modal_show:false,
        min_deposit:10,
        lastDistribution:Math.floor(Date.now() / 1000),
        current_timestamp: Math.floor(Date.now() / 1000),
        game_time_interval:60*60,
        orderInfos:[],
        current_game_id:1,
        tokenPriceUSD:1,
        UsdToBnb:0,
        user_type:0,
        player_address:'',
        amountDistributor:'',
        getDistributorData:[],
        getDistributorAddressLength:0,
        distributor_list:[],
        selected_number:'',
        selected_distributor:'',
        player_buy_amount:10,
        getUserData:[],
        getUsergameIdLength:0,
        refund_button_show:true,
        user_history:[],
        distributor_history:[],
        getCommison:0,
        refund_time:5*60*1000,
        buy_qty:1,
        buy_modal_show:false,
        last_winning_no:'',
        selected_d_n_balance:0,
        gameData:[],
        defaultStockiest:'',
        inputStockiest:'',
        location_data:null,
        maintence_modal_show:false,
        admin:'',
        userSide:'0',
        type:'0',
        payAmount:99
      }
    }

    componentDidMount = async () => {
      try {
        let lat;
        let lng
        let location_data = await this.getData();
        this.setState({location_data});
          
  
       
        let web3Modal = new Web3Modal({ cacheProvider: true, providerOptions });
        if (web3Modal.cachedProvider) {
          let web3ModalInstance = await web3Modal.connect();
          await web3ModalInstance.enable();
          let web3 = new Web3(web3ModalInstance);
          let accounts = await web3.eth.getAccounts();
  
          // Subscribe to accounts change
          web3ModalInstance.on("accountsChanged", (accounts) => {
            console.log(accounts);
            console.log(this.state.accounts);
            if(accounts.length){
              //accounts = accounts;
              this.setState({accounts:accounts[0]});
            }else{
              //this.disconnectFromWallet();
              //this.connectToWallet();
            }
            //this.disconnectFromWallet();
            this.connectToWallet();
          });
  
          // Subscribe to chainId change
          web3ModalInstance.on("chainChanged", (chainId) => {
            this.disconnectFromWallet();
            //this.connectToWallet();
          });
  
          // Subscribe to provider connection
          web3ModalInstance.on("connect", (info) => {            
            this.connectToWallet();
          });
  
          // Subscribe to provider disconnection
          web3ModalInstance.on("disconnect", (error) => {           
            this.disconnectFromWallet();
          });
  
          const networkId = await web3.eth.net.getId();
          //if(this.state.accounts){
          if(networkId !== 56) {
            this.props.enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
            this.setState({loading:false});
            return false;            
          }
          //}
          // Use web3 to get the user's accounts.
  
          if(this.state.accounts !== accounts[0]){
            // Get the contract instance.
            
            const deployedNetwork = Xakpot.networks;
            const instance = new web3.eth.Contract(
              Xakpot.abi,
              deployedNetwork && Xakpot.address,
            );
  
            let user_address = accounts[0];
  
            this.setState({loading:false, is_connected:true,});
            this.setState({ web3, accounts:user_address, contract: instance, contractAddress:Xakpot.address}, this.fetchData);
            
          }
        }
  
      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false, loading:false});
        //console.error(error);
      }
    };

    connectToWallet = async (e) => {
      //e.preventDefault();
      //alert('kk');
      try {        
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
        // Use web3 to get the user's accounts.
        const accounts = await web3.eth.getAccounts();
  
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        if(networkId !== 56) {
          this.props.enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
          this.setState({loading:false});
          return false;
        }
         
        if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          
          this.setState({loading:true});
          const deployedNetwork = Xakpot.networks;
          const instance = new web3.eth.Contract(
            Xakpot.abi,
            deployedNetwork && Xakpot.address,
          );
  
          let user_address = accounts[0];
  
          this.setState({loading:false, is_connected:true});
          this.setState({ web3, accounts:user_address, contract: instance, contractAddress:Xakpot.address}, this.fetchData);
          this.props.enqueueSnackbar("Wallet Connected",{ variant: 'success' });
        }else{
          this.setState({loading:false, is_connected:true});
          this.props.enqueueSnackbar("Wallet Connected",{ variant: 'success' });
        }
      } catch (error) {
        // Catch any errors for any of the above operations.
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
        this.setState({networkMain:false});
        console.error(error);
        this.setState({loading:false});
      }      
    };

    getData=async()=>{
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(function(position) {
           resolve(position.coords)
        });
      })
    }
  
    disconnectFromWallet = async () => {
      try {
        this.setState({loading:true});
        let web3Modal = new Web3Modal({ cacheProvider: false, providerOptions });
        
        await web3Modal.clearCachedProvider();
  
        //await web3Modal.current.clearCachedProvider();
        //window.localStorage.clear();
        if(this.state.web3){
          this.props.enqueueSnackbar("Wallet disconnected",{ variant: 'success' });
        }
        
        this.setState(initialState);
        
      } catch (error) {
        // Catch any errors for any of the above operations.
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
        this.setState({networkMain:false});
        console.error(error);
        this.setState({loading:false});
      } 
    }

    // setDistributerData=async()=>{
    //   try {
    //     console.log('this.state.YourAddressfull',this.state.userInfo.YourAddressfull)
    //     this.setState({isLoading:true});
    //     const res1 = await fetch('http://103.234.187.61:8558/api/getData',{
    //     headers:{'Content-Type':'application/json'},
    //     method:"GET",
    //     mode:'cors',
    //     crossDomain:true,
    //   });
    //   console.log('res1',res1)
    //   return
    //   const res = await fetch('https://103.234.187.61:8558/api/set_lat_lng',{
    //     headers:{
    //       Accept:'application/json',
    //       'Content-Type':'application/json',
    //     },
    //     method:"POST",
    //     body:JSON.stringify({
    //       address:this.state.userInfo.YourAddressfull,
    //       lat:this.state?.location_data.latitude,
    //       lng:this.state?.location_data.longitude
    //     })

    //   });
    //   const response = await res.json();
    //   console.log('res',res)
    //   if(response){
    //     console.log('response',response)
    //     return response
    //   }
    //   } catch (error) {
    //     console.log('error',error);
    //   }
    // }
  
    fetchData= async()=>{
      const { accounts, contract } = this.state;

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      
      const contractAddress = this.state.contractAddress;
      const users = await contract.methods.users(accounts).call();
      if(!users.isRegisterd){
        this.setState({register_modal_show:true});
      }else{
        this.setState({register_modal_show:false});
      }      

      this.setState(({contractInfo})=>
        ({contractInfo : {...contractInfo, 
          contractAddress:contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length),
          contractAddressLinkto:contractAddress
        }})
      );      
      
      let withdrawan = users.withdrawan;
      
      this.setState(({userInfo})=>
        ({userInfo : {...userInfo, 
          YourAddress: YourAddress,
          YourAddressfull:YourAddressfull,
          withdrawan:withdrawan?withdrawan/1e8:0,
          isRegisterd: users.isRegisterd,
          stockiest:users.stockiest,
          userType:users.userType
        }})
      );

      let min_deposit = await contract.methods.min_amount().call();
      min_deposit = min_deposit/1e8;

      let current_game_id  = await contract.methods.gameNo().call();
      let lastDistribution  = await contract.methods.lastDistribution().call();
      let game_time_interval  = await contract.methods.time_stamp().call();
      let tokenPriceUSD  = await contract.methods.tokenPriceUSD().call();
      tokenPriceUSD = tokenPriceUSD/1e8;

      let getCommison  = await contract.methods.getCommison(accounts).call();
      getCommison = getCommison/1e8;
      
      let getLatestPrice = await contract.methods.getLatestPrice().call();
      let UsdToBnb = 0;
      if(getLatestPrice){
        UsdToBnb = getLatestPrice/1e8;
      }
      
      let getDistributorData = await contract.methods.getDistributorData(accounts,current_game_id).call();
      let getUserData = await contract.methods.getUserData(accounts,current_game_id).call();
      let last_winning_no = '';
      if(current_game_id > 1){
        let games = await this.state.contract.methods.games(current_game_id-1).call();
        last_winning_no = games.winingNo;
      }

      let defaultStockiest = await this.state.contract.methods.defaultStockiest().call();
      console.log(defaultStockiest)
      
      let gameData = [];
      let id =current_game_id;
      if(current_game_id>50){
        id = 50;
      }
      let no = current_game_id;
      let admin  = await this.state.contract.methods.admin().call();
      console.log("ssss",no)
      for(let y =1;y<=id;y++){
        console.log("sad")
          let games = await this.state.contract.methods.games(current_game_id-y).call();
          console.log(games);
          if(games.endtime !=0){
            let time = games.endtime*1000;
            time = moment(time).format('lll');
            gameData.push({winingNo:games.winingNo,time:time})
          }
        
      }
      this.setState({loading:false,admin,last_winning_no,current_game_id,lastDistribution,game_time_interval,
        min_deposit,tokenPriceUSD,gameData,getCommison,defaultStockiest,UsdToBnb,getDistributorData,getUserData},async()=>{
          let distributor_list = [];
          let getDistributorAddressLength = await this.state.contract.methods.getDistributorAddressLength(this.state.current_game_id).call();
          
          if(getDistributorAddressLength>0){
            for(let i=0;i<getDistributorAddressLength;i++){
              let distributorAddress = await this.state.contract.methods.distributorAddress(this.state.current_game_id,i).call();
              let distributorInfos = await this.state.contract.methods.getDistributorData(distributorAddress,this.state.current_game_id).call();
              let distributorAddress_short = distributorAddress.slice(0, 10) + '.....' + distributorAddress.slice(distributorAddress.length-5, distributorAddress.length)
              distributor_list.push({distributorAddress,distributorAddress_short,distributorInfos});
            }
            this.setState({getDistributorAddressLength,distributor_list:distributor_list});
          }

          let user_history = [];
          let getUsergameIdLength = await this.state.contract.methods.getUsergameIdLength(this.state.accounts).call();
          //console.log(getUsergameIdLength);
          //getUsergameIdLength -= 1; 
          
          if(getUsergameIdLength>0){
            for(let i=0;i<getUsergameIdLength;i++){
              let getUserGameID = await this.state.contract.methods.getUserGameID(this.state.accounts,i).call();
              if(getUserGameID == this.state.current_game_id){
                break;
              }
              let getHistory = await this.state.contract.methods.getUserData(this.state.accounts,getUserGameID).call();
              let games = await this.state.contract.methods.games(getUserGameID).call();
              let winingNo = games.winingNo;
              let rewardInfo = await this.state.contract.methods.rewardInfo(this.state.accounts,getUserGameID).call();
              let claimInfo = await this.state.contract.methods.claimInfo(getUserGameID,this.state.accounts).call();

              user_history.push({game_id:getUserGameID,getHistory,games,winingNo,rewardInfo,claimInfo});
            }
            //console.log(user_history);
            this.setState({getUsergameIdLength,user_history:user_history});
          }

          let distributor_history = [];
          if(getUsergameIdLength>0){
            for(let i=0;i<getUsergameIdLength;i++){
              let getUserGameID = await this.state.contract.methods.getUserGameID(this.state.accounts,i).call();
              if(getUserGameID == this.state.current_game_id){
                break;
              }
              let getDistributorData = await this.state.contract.methods.getDistributorData(this.state.accounts,getUserGameID).call();
              let games = await this.state.contract.methods.games(getUserGameID).call();
              let winingNo = games.winingNo;
              let rewardInfo = await this.state.contract.methods.rewardInfo(this.state.accounts,getUserGameID).call();
              let claimInfo = await this.state.contract.methods.claimInfo(getUserGameID,this.state.accounts).call();

              distributor_history.push({game_id:getUserGameID,getDistributorData,games,winingNo,rewardInfo,claimInfo});
            }
            //console.log(distributor_history);
            this.setState({distributor_history:distributor_history});
          }

        });

    }

    doRegister = async(e) => {
      try {
        if(!this.state.is_connected){
          this.handleClose();
          // this.setState({,register_modal_show:false});
          this.props.enqueueSnackbar("Please Connect Your Wallet",{ variant: 'error' })
          return
        }
        this.setState({loading:true});
        let stockiest;
        if(this.state.user_type==1){
          // const result = await this.setDistributerData();
          // console.log('result',result)
          // return
          stockiest = this.state.inputStockiest
          let is_register = await this.state.contract.methods.users(stockiest).call();
          if(!is_register.isRegisterd){
            this.handleClose();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Stockiest Not Register",{ variant: 'error' })
            return
          }
          if(is_register.userType !="stockiest"){
            this.handleClose();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Stockiest Not Register",{ variant: 'error' })
            return
          }
        }else{
          stockiest = this.state.defaultStockiest
        }
        let Sponsor = this.state.sponsor_address;
        if(!Sponsor){
          Sponsor = '0x0000000000000000000000000000000000000000'
        }
        let weiValue =0;
        if(this.state.user_type==2){
          let data = await this.state.contract.methods.users(this.state.sponsor_address).call();
          if(this.state.accounts != this.state.admin){
            if(data.isstockiest ==false){
            this.handleClose();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Invalid Sponsor",{ variant: 'error' })
              return
            }
          }
          let balance = await this.state.web3.eth.getBalance(this.state.accounts);
          let balanceEthVal =  this.state.web3.utils.fromWei(balance, 'ether');
          let amountBNB = parseFloat(this.state.payAmount/this.state.UsdToBnb);
          amountBNB = amountBNB+amountBNB*0.005;
          weiValue = amountBNB*1e18
          console.log(parseFloat(amountBNB),parseFloat(balanceEthVal));
          if(parseFloat(amountBNB)>parseFloat(balanceEthVal)){
            this.handleClose();
            this.setState({loading:false});
            this.props.enqueueSnackbar("No funds",{ variant: 'error' })
            return
            }
        
          // return
          let datanew = await addressRegister(this.state.accounts,Sponsor,this.state.userSide,this.state.type)
          
        }
    
        const data = await this.state.contract.methods.register(this.state.user_type,stockiest.toString(),
        Sponsor.toString(),this.state.userSide).send(
          {
            from: this.state.accounts,
            value:weiValue
          }
        );
        if(data.status){
          // if(this.state.user_type==1){
          //   const result = await this.setDistributerData();
          //   if(result){
          //       this.handleClose();
          //       this.setState({loading:false,register_modal_show:false});
          //       this.props.enqueueSnackbar("Registered successfully!",{ variant: 'success' })
          //       this.fetchData();
          //   }
          // }else{
            this.handleClose();
            this.setState({loading:false,register_modal_show:false});
            this.props.enqueueSnackbar("Registered successfully!",{ variant: 'success' })
            this.fetchData();
          // }
          
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
          //////////// transaction error ////////////
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }
        if(err.includes('Confirmation declined by user')){
          defaultError = err;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }

    doMakeDistributor = async(e) => {
      if(!this.state.player_address){
        this.props.enqueueSnackbar("Player address is required!",{ variant: 'error' });
        return false;
      }
      let users = await this.state.contract.methods.users(this.state.player_address).call();
      if(users.userType != 'player'){
        this.props.enqueueSnackbar("User is not valid!",{ variant: 'error' });
        return false;
      }
  
      try {
        this.setState({loading:true});
        const data= await this.state.contract.methods.makeDistributor(this.state.player_address).send(
          {
            from: this.state.accounts,
            value:0
          }
        );
        if(data.status){
          this.setState({loading:false,player_address:''});
          this.props.enqueueSnackbar("Congratulation! You make distributor successfully! ",{ variant: 'success' })
          this.fetchData();
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
          //////////// transaction error ////////////
        }        
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }
        if(err.includes('Confirmation declined by user')){
          defaultError = err;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }

    dBuy = async(e) => {
      
      let amountDistributor = this.state.amountDistributor;
      if(!amountDistributor){
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' });
        return false;
      }
      if(amountDistributor < 10){
        this.props.enqueueSnackbar("Minimum 10 USD is required!",{ variant: 'error' });
        return false;
      }

      if(!this.state.userInfo.isRegisterd){
        this.props.enqueueSnackbar("Register To Buy ",{ variant: 'error' });
        return false;
      }
  
      try {
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);
        let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
        let amountBNB = parseFloat(amountDistributor/this.state.UsdToBnb);
        amountBNB = amountBNB+amountBNB*0.05
        if(parseFloat(balanceEthVal) >= parseFloat(amountBNB)){
          this.setState({loading:true});
          amountDistributor = amountDistributor*1e8;
          let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
          const data= await this.state.contract.methods.buy(amountDistributor).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          if(data.status){
            this.setState({loading:false,player_address:'',amountDistributor:''});
            this.props.enqueueSnackbar("Buy successfully! ",{ variant: 'success' })
            this.fetchData();
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
            //////////// transaction error ////////////
          }
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' })                
        }
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }else
        if(err.message.includes('Confirmation declined by user')){
          defaultError = err.message;
        }else
        if(err.message.includes('MetaMask Tx Signature')){
          defaultError = err.message;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }

    playerBuy = async(d_address) => {      
      let selected_number = this.state.selected_number;
      console.log(selected_number);
      //let player_buy_amount = this.state.player_buy_amount;
      let player_buy_amount = this.state.min_deposit*this.state.buy_qty;
      if(!this.state.userInfo.isRegisterd){
        this.props.enqueueSnackbar("Register To Buy Balls",{ variant: 'error' });
        return false;
      }
      if(selected_number === ''){
        this.props.enqueueSnackbar("Please pick a number!",{ variant: 'error' });
        return false;
      }

      if(this.state.selected_d_n_balance < player_buy_amount){
        this.props.enqueueSnackbar("Distributor don't have enough funds!",{ variant: 'error' });
        return false;
      }
  
      try {
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);
        let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
        let amountBNB = player_buy_amount/this.state.UsdToBnb;
        amountBNB = amountBNB + (amountBNB*1)/100;
        if(parseFloat(balanceEthVal) >= parseFloat(amountBNB)){
          this.setState({loading:true});
          player_buy_amount = player_buy_amount*1e8;
          let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');

          const data= await this.state.contract.methods.playerBuy(d_address,selected_number,this.state.buy_qty).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          if(data.status){
            this.setState({loading:false,d_address:'',selected_number:'',buy_qty:1});
            this.handleCloseBuy();
            this.props.enqueueSnackbar("Buy successfully! ",{ variant: 'success' });
            this.fetchData();
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
            //////////// transaction error ////////////
          }
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' })                
        }
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }else
        if(err.message.includes('Confirmation declined by user')){
          defaultError = err.message;
        }else
        if(err.message.includes('MetaMask Tx Signature')){
          defaultError = err.message;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }

    dRefund = async() => {
      let game_end_time = parseInt(this.state.lastDistribution*1000)+parseInt(this.state.game_time_interval*1000);
      let current_timestamp = Date.now();
      let time_remaing = game_end_time - current_timestamp;
      let refund_time = this.state.refund_time;
      if(time_remaing < refund_time){
        this.props.enqueueSnackbar("Refund time is over!",{ variant: 'error' });
        return false;
      }

      let refund_status = true;
      for (var i = 0; i < default_lottery_numbers.length; i++) {
        let ball_value = this.state.getDistributorData[i]?this.state.getDistributorData[i]/1e8:0;
        if(ball_value == 0){
          refund_status = false;
          break;
        }
      }
      
      if(!refund_status){
        this.props.enqueueSnackbar("No funds available for refund!",{ variant: 'error' });
        return false;
      }
  
      try {
        this.setState({loading:true});

        const data= await this.state.contract.methods.refund().send(
          {
            from: this.state.accounts,
            value:0
          }
        );
        if(data.status){
          this.setState({loading:false,selected_number:''});
          this.props.enqueueSnackbar("Refund successfully! ",{ variant: 'success' })
          this.fetchData();
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
          //////////// transaction error ////////////
        }
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }else
        if(err.message.includes('Confirmation declined by user')){
          defaultError = err.message;
        }else
        if(err.message.includes('MetaMask Tx Signature')){
          defaultError = err.message;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }      
    }
  
    dWithdraw = async(e) => {
      if(!this.state.getCommison){
        this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' });
        this.setState({withdrawlAmountError:true});
        return false;
      }      
  
      try {
        this.setState({loading:true});
        const data = await this.state.contract.methods.withdraw().send(
          {
            from: this.state.accounts,
            value:0
          }
        );
        if(data.status){
          this.setState({loading:false});
          this.props.enqueueSnackbar("Congratulation! Your Withdrawl made successfully!",{ variant: 'success' })
          this.fetchData();
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' });
          this.setState({loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }else
        if(err.message.includes('Confirmation declined by user')){
          defaultError = err.message;
        }else
        if(err.message.includes('MetaMask Tx Signature')){
          defaultError = err.message;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }    

    validDater =()=>{
      // if(!this.state.location_data){
      //   this.setState({loading:false});
      //   this.props.enqueueSnackbar("Please enable your Loction",{ variant: 'error' })
      //   return
      // }

      this.setState({register_modal_show:true})
    }
    claimReward = async(game_id) => {
      try {
        this.setState({loading:true});
        const data = await this.state.contract.methods.claimReward(game_id).send(
          {
            from: this.state.accounts,
            value:0
          }
        );
        if(data.status){
          this.setState({loading:false});
          this.props.enqueueSnackbar("Claimed successfully!",{ variant: 'success' })
          this.fetchData();
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' });
          this.setState({loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        //console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred!';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = err.output.contractResult[0];
        }else
        if(err.message.includes('Confirmation declined by user')){
          defaultError = err.message;
        }else
        if(err.message.includes('MetaMask Tx Signature')){
          defaultError = err.message;
        }
        this.props.enqueueSnackbar(err,{ variant: 'error' })
      }
      
    }

    handleClose = () => {
      this.setState({register_modal_show:false});
    }

    handleCloseBuy = () => {
      this.setState({buy_modal_show:false});
    }
    connectionError = () => {
    this.props.enqueueSnackbar("Please Connect Wallet",{ variant: 'error' });
    return false;
  
    }

    render() {
      let this2 = this;
      let game_end_time = parseInt(this.state.lastDistribution*1000)+parseInt(this.state.game_time_interval*1000);
      let current_timestamp = Date.now();
      let time_remaing = game_end_time - current_timestamp;      
      let refund_time = this.state.refund_time;
      let refund_end = game_end_time - refund_time;
      let refund_button_show = true;
      if(time_remaing < refund_time){
        refund_button_show = false;
      }

      console.log(this.state.is_connected);
      
      return (
        <Frame withHeader={false} withFooter={true} isHome={true}>

        <header>
          <div className="container">
            <div className="header_box">
              <nav className="navbar navbar-expand-md">
                <div className="logo">
                    <a className="navbar-brand" href="/">
                      <img src="img/logo.png" alt="" />
                    </a>
                </div>
                <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-expanded="false">
                  <img src="img/menu.png" alt="" />
                </button>
              
                <div class="navbar-collapse collapse" id="collapsibleNavbar">
                  <ul className='navbar-nav mr-auto'>
                  
                  </ul>
                  <ul className="navbar-nav">
                  <li class="nav-item">
                      <Link  className="btn btn-round btn-primary wallet_btn btn-lg"to="/wallet">
                      <img src="/img/binary.png" /> Binary
                      </Link>  
                    </li>
                    <li  className="nav-item">
                      <Link className="btn btn-round btn-primary wallet_btn btn-lg" to="/swap">
                      <img src="/img/swap.png" /> Swap
                      </Link>  
                    </li>
                    <li className="nav-item">
                      <Link className="btn btn-round btn-primary wallet_btn btn-lg" onClick={()=>this.validDater()}>
                      <img src="/img/register.png" /> Register
                      </Link>
                    </li>
                  {this.state.is_connected ? (
                      <li className="nav-item">
                      <button type="button" disabled={this.state.loading} onClick={()=>this.disconnectFromWallet()} className="btn btn-round btn-primary wallet_btn btn-lg">
                        <img src='/img/c-wallet.png'></img>
                        <span>Wallet Disconnect</span>
                      </button>
                      </li>
                    ):(
                      <li className="nav-item">
                      <button type="button" className="btn btn-round wallet_btn btn-primary btn-lg" disabled={this.state.loading} onClick={()=>this.connectToWallet()}>
                        <img src='/img/c-wallet.png'></img>
                        <span>Wallet Connect</span>
                      </button>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
              
            </div>
          </div>
        </header>

        {this.state.loading ? (
            <div className="loader-container">
              <div className="wrapper">
                <div className="left"></div>
                <div className="right"></div>
              </div>
            </div>
          ) : (null)}

          <Modal
            show={(this.state.register_modal_show)}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
            //size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >           
            <Modal.Body className="modal_bg">
            <button onClick={()=>{this.setState({register_modal_show:false})}} type="button" className="modal_close" data-dismiss="modal">
              <img src="img/close.png" />
            </button>
              <div >
                <div className="modal_text">
                  <h4 className="text-center mb-4"><strong>Register on the platform as </strong></h4>
                  <ul className="registratin_ul">
                    <li onClick={()=>this.setState({user_type:0})} className={this.state.user_type == 0 ? 'active':''}><button>Player</button></li>
                    <li onClick={()=>this.setState({user_type:1})} className={this.state.user_type == 1 ? 'active':''}><button>Retailer</button></li>
                    {this.state.user_type==1?(
                      <>
                        <div className='mb-3'>
                            <input className='form-control' type="text" placeholder='Stokiest Address' value={this.state?.inputStockiest ||''}
                          onChange={(e)=>{this.setState({inputStockiest:e.target.value})}}/>
                        </div>
                        <span style={{cursor:'pointer'}} className='theme_color'onClick={()=>{this.setState({inputStockiest:this.state.defaultStockiest})}}>Not Have Distributor? Click here</span>
                        </>):(null)}

                    <li onClick={()=>this.setState({user_type:2})} className={this.state.user_type == 2 ? 'active':''}><button>Distributor</button></li>
                    {this.state.user_type==2?(
                      <>
                        <div className='mb-3'>
                            <input className='form-control' type="text" placeholder='Sponsor Address' value={this.state?.sponsor_address ||''}
                          onChange={(e)=>{this.setState({sponsor_address:e.target.value})}}/>
                        </div>
                        <div className="form-group">
                        <label className="form-label">Choose your Side</label>
                        <select disabled={this.state.currentSubAmount>0} className="form-control" value={this.state.userSide} onChange={(e)=>this.setState({userSide:e.target.value})}>
                          <option value={0}>Left</option>
                          <option value={1}>Right</option>
                        </select>
                      </div>
                        {/* <span style={{cursor:'pointer'}} className='theme_color'onClick={()=>{this.setState({inputStockiest:this.state.defaultStockiest})}}>Not Have Stockiest? Click here</span> */}
                        </>):(null)}
                  </ul>
                  <hr className="mb-4 mt-4" />
                  <button onClick={this.doRegister} disabled={this.state.loading} className="btn btn-round btn-primary btn-block btn-lg">{this.state.user_type==2?'Pay $99 and Register':'Regeister'}</button>
                
                </div>
              </div>
            </Modal.Body>            
          </Modal>

          <Modal
            show={(this.state.maintence_modal_show)}
            onHide={()=>this.setState({maintence_modal_show:false})}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >           
            <Modal.Body className="modal_bg">
              
            <button onClick={()=>{this.setState({maintence_modal_show:false})}} type="button" className="modal_close" data-dismiss="modal">
              <img src="img/close.png" />
              
            </button>
              <div >
                <div className="modal_text text-center" >
                <img src='img/setting.png'></img>

                <div class="heading-text mb-4 text-center">
                  <h2>Our system is under maintenance</h2>
                </div>

                
                  <hr className="mb-4 mt-4" />
                  <h7 className="text-center mb-4">So, some of the features may not be functional for a few hours. We appreciate your patience during this time.</h7>
                
                </div>
              </div>
            </Modal.Body>            
          </Modal>


          <section className="banner_bg">
            <div className="shape right_shape"></div>
            <div className="shape2 left_shape"></div>
            <div className="container">
              <div className="banner_inner">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="banner_text">
                      <h1>Introducing the next level of Gambling!</h1>
                      <p>We are the world’s leading decentralized casino platform providing the top gambling services. Select and play with your favorite number to earn.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <img className="banner_img" src="img/banner-img2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <img className="ball2" src="img/ball01.webp" alt="" />
            <img className="ball01 rotation_img" src="img/ball2.png" alt="" />
            <img className="ball0" src="img/ball0.webp" alt="" />
          </section>
          
              <div className="mb-5">
                {this.state.gameData.length>0?(
                  <div className="heading-text mb-5 text-center">
                  <h2>Last 10 Games Winning Numbers</h2>
                </div>
                ):(null)}
              
              <div className="text-center selected_ball_inner">
          {this.state.gameData.length>0?(
           this.state.gameData.map((item,key) => {                              
            return(
                <div className="selected_ball_box" key={`my_xgame_${key}`}>
                    <div className={`selected_ball${item.winingNo}`}>{item.winingNo}</div> 
                    <span>{item.time}</span> 
                  </div>   )})
          ):(null)}
              </div>  
            </div>
           
          

          <section>
            <div className="container">
              <div className="countdown_box">
             
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    
                    <h5 className='text-center last_w_n mb-3'>Last Game Winning Number</h5>
                    <div className="selected_ball_inner big_ball rotation_img text-center mb-3">
                      <div className="selected_ball_box">
                        <div className={`selected_ball${this.state.last_winning_no || 0}`}>{this.state.last_winning_no || '?'}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4">
                  <h2 className='text-center'>Lottery Jackpot</h2>
                    <div className="next_drawn_box">
                      <h5>Next Drawn in</h5>
                      <div id="countdown">
                        {this.state.is_connected ? (
                          <Countdown intervalDelay={0} key='drawing'
                            precision={3} date={this.state.lastDistribution*1000+this.state.game_time_interval*1000}
                            daysInHours={true}
                            renderer={props => (
                              <ul>
                                <li>
                                  <span className="days_text" id="hours">{props.hours}</span>
                                  <span className="smalltext">h:</span>
                                </li> 
                                <li>
                                  <span className="days_text" id="minutes">{props.minutes}</span>
                                  <span className="smalltext">m:</span>
                                </li> 
                                <li>
                                  <span className="days_text" id="seconds">{props.seconds}</span>
                                  <span className="smalltext">s</span>
                                </li> 
                              </ul>
                            )}
                          />
                        ) : (
                          <Countdown intervalDelay={0} key='drawing'
                            precision={3} date={this.state.lastDistribution*1000+this.state.game_time_interval*1000}
                            daysInHours={true}
                            renderer={props => (
                              <ul>
                                <li>
                                  <span className="days_text" id="hours">--</span>
                                </li> 
                                <li>
                                  <span className="days_text" id="minutes">--</span>
                                </li> 
                                <li>
                                  <span className="days_text" id="seconds">--</span>
                                </li> 
                              </ul>
                            )}
                          />
                        )}                        
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <img className="next_drawn_img" src="img/ball1.png" alt="" /> 
                  </div>
                </div>

                <img className="countdown_ball1" src="img/ticket-clipart.png" alt="" />
                <img className="dollar1" src="img/dollar1.png" alt="" />
                <img className="dollar2" src="img/dollar2.png" alt="" />
                <img className="dollar3" src="img/dollar3.png" alt="" />
                <img className="dollar4" src="img/dollar4.png" alt="" />
              </div>
            </div>
          </section>

          {this.state.userInfo.userType != 'distributor' && (
            <section className="pt-5 mt-4 pb-4  position-relative">
              <div className="shape left_shape"></div>
               <div className="container">
                <div className="md_container container_fill">
                  <div className="mb-5">
                    <div className="heading-text mb-5 text-center">
                      <h2>Balls Selected by Players</h2>
                    </div>
                    <div className="text-center selected_ball_inner">
                    {default_lottery_numbers.map((val,key) => {
                      let _class = '';
                      if(this.state.getUserData[key]>0){
                        _class = 'selected_ball'+key;
                      }
                      return(
                        <div className="selected_ball_box" key={`my_game_${key}`}>
                          <div className={`${_class}`}>{val}</div> 
                          <span>${this.state.getUserData[key] ? this.state.getUserData[key]/1e8:0}</span> 
                        </div>  
                      ) 
                    })}                  
                    </div>  
                  </div>

                  <div className="heading-text mb-4">
                      <h2>Retailers</h2>
                  </div>
                
                  <div className={this.state.getDistributorAddressLength>0?'d-scroll':''}>
                    {this.state.getDistributorAddressLength>0 ? (
                      this.state.distributor_list.map((item,key) => {                              
                        return(
                          <div className="shadow_box p-4" key={`distri${key}`} style={key==0?{marginTop:0}:{marginTop:20}}>
                            <div className="shadow_border">
                              <div className="row align-items-center">
                                <div className="col-md-4 col-lg-4">
                                  <h6 className="mb-3">Retailer address ------</h6>
                                  <h5>{item.distributorAddress_short}</h5>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                  <div className="balls_box mt-3 mb-3">
                                    {default_lottery_numbers.map((val,key) => {
                                      let _class = 'inactive';
                                      if(this2.state.selected_number===val && this2.state.selected_distributor==item.distributorAddress){
                                        _class = 'selected_ball'+key;
                                      }
                                      return(
                                        <div key={`dnum${key}`}>
                                          <button onClick={()=>this2.setState({selected_number:val,selected_distributor:item.distributorAddress,selected_d_n_balance:item.distributorInfos[key]?item.distributorInfos[key]/1e8:0})} className={`balls ${_class}`}>{val}</button>
                                          <span className="number">${item.distributorInfos[key]?item.distributorInfos[key]/1e8:0}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                                <div className="col-md-2 col-lg-2 text-right">
                                  {/* <button onClick={()=>this.playerBuy(item.distributorAddress)} disabled={this.state.loading} className="btn btn-round btn-block btn-primary btn-lg">BUY</button> */}
                                  {/* <button  data-toggle="modal" data-target="#buy_ball" className="btn btn-round btn-block btn-primary btn-lg">BUY </button> */}
                                  <button onClick={()=>this.setState({distributorAddress:item.distributorAddress,buy_modal_show:true})} className="btn btn-round btn-block btn-primary btn-lg">BUY </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })                      
                    ) : (
                      <div className="shadow_box p-4" style={{marginTop:0}}>
                        <div className="shadow_border">
                          <div className="text-center">
                            <img className='no_Distributor_img' src="img/not-found.png" alt="" />
                            <p className='mb-0'>No Retailer available!</p>                        
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}

          <Modal
            show={this.state.buy_modal_show}
            onHide={this.handleCloseBuy}
            backdrop="static"
            keyboard={false}
            //size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >           
            <Modal.Body className="modal_bg">
              <button type="button" className="modal_close" onClick={this.handleCloseBuy}>
                <img src="img/close.png" alt="" />
              </button>
              <div className="modal_text">
                <h4 className="text-center mb-4"><strong>Add quantity Here!</strong></h4>
                {this.state.selected_number !=='' ? (
                  <div className="selected_ball_inner text-center mb-4">
                    <div className="selected_ball_box">
                      <div className={`selected_ball${this.state.selected_number}`}>{this.state.selected_number}</div><span>{this.state.buy_qty}x${this.state.player_buy_amount}</span>
                    </div>
                  </div>
                ):(
                  <h5 className="text-center mb-4"><strong>Please select number first!</strong></h5>
                )}                  

                <div className="number_increase">
                  <button onClick={()=>{
                    if(parseInt(this.state.buy_qty) > 1){
                      this.setState({buy_qty:parseInt(this.state.buy_qty)-1});
                    }else{
                      this.setState({buy_qty:1});
                    }
                  }} className="number_btn" data-decrease><i className="fa fa-minus"></i></button>
                    <input onChange={(e)=>{
                      if(parseInt(e.target.value) >=1){
                        this.setState({buy_qty:parseInt(e.target.value)});
                      }else{
                        this.setState({buy_qty:1});
                      }
                    }} data-value type="number" value={this.state.buy_qty || 1} />
                  <button onClick={()=>{
                    if(parseInt(this.state.buy_qty) >=1){
                      this.setState({buy_qty:parseInt(this.state.buy_qty)+1});
                    }else{
                      this.setState({buy_qty:1});
                    }                        
                  }} className="number_btn" data-increase><i className="fa fa-plus"></i></button>       
                </div>
                <hr className="mb-4 mt-4" />
                <button onClick={()=>this.playerBuy(this.state.distributorAddress)} disabled={this.state.loading} className="btn btn-round btn-primary btn-block btn-lg">Buy</button>
              
              </div>
            </Modal.Body>            
          </Modal>

          <div className="modal fade" id="buy_ball">
            <div className="modal-dialog">
              <div className="modal-content">
            
                <div className="modal-body modal_bg">
                <button type="button" className="modal_close" data-dismiss="modal">
                  <img src="img/close.png" alt="" />
                </button>
                <div className="modal_text">
                  <h4 className="text-center mb-4"><strong>Add quantity Here!</strong></h4>
                  {this.state.selected_number ? (
                    <div className="selected_ball_inner text-center mb-4">
                      <div className="selected_ball_box">
                        <div className={`selected_ball${this.state.selected_number}`}>{this.state.selected_number}</div><span>{this.state.buy_qty}x${this.state.player_buy_amount}</span>
                      </div>
                    </div>
                  ):(
                    <h5 className="text-center mb-4"><strong>Please select number first!</strong></h5>
                  )}                  

                  <div className="number_increase">
                    <button onClick={()=>{
                      if(parseInt(this.state.buy_qty) > 1){
                        this.setState({buy_qty:parseInt(this.state.buy_qty)-1});
                      }else{
                        this.setState({buy_qty:1});
                      }
                    }} className="number_btn" data-decrease><i className="fa fa-minus"></i></button>
                      <input onChange={(e)=>{
                        if(parseInt(e.target.value) >=1){
                          this.setState({buy_qty:parseInt(e.target.value)});
                        }else{
                          this.setState({buy_qty:1});
                        }
                      }} data-value type="number" value={this.state.buy_qty || 1} />
                    <button onClick={()=>{
                      if(parseInt(this.state.buy_qty) >=1){
                        this.setState({buy_qty:parseInt(this.state.buy_qty)+1});
                      }else{
                        this.setState({buy_qty:1});
                      }                        
                    }} className="number_btn" data-increase><i className="fa fa-plus"></i></button>       
                  </div>
                  <hr className="mb-4 mt-4" />
                  <button onClick={()=>this.playerBuy(this.state.distributorAddress)} disabled={this.state.loading} className="btn btn-round btn-primary btn-block btn-lg">Buy</button>
                
                </div>
                </div>

              </div>
            </div>
          </div>



          {this.state.userInfo.userType == 'distributor' && (
            <section className="pt-5 pb-0">
         
              <div className="container">
                <div className="md_container container_fill">
                  <div className="heading-text mb-4">
                    <h2>Retailer</h2>
                  </div>
                  <div className="shadow_box p-4">
                    <div className="shadow_border">
                      <div className="row align-items-center">
                        <div className="col-md-4 col-lg-4">
                          <div className="mb-0">
                            <input type="text" placeholder="Amount" className="form-control" 
                              value={this.state.amountDistributor || ''}
                              onChange={(e)=>this.setState({amountDistributor:e.target.value})}
                            />
                          </div>
                          
                        </div>
                        <div className="col-md-5 col-lg-5">
                          <div className="balls_box mt-3 mb-3">
                            {default_lottery_numbers.map((val,key) => {                              
                              return(
                                <div key={`lnum${key}`}>
                                  <button className="balls">{val}</button><span className="number">${this.state.getDistributorData[key]?this.state.getDistributorData[key]/1e8:0}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3 text-right">
                          <Countdown intervalDelay={0} key='refund'
                            precision={3} date={refund_end}
                            onComplete={()=>this.setState({refund_button_show:false})}
                            daysInHours={true}
                            renderer={props => (
                              <span style={{display:'none'}}>
                              {props.hours}<span>H</span> {props.minutes}<span>M</span> {props.seconds}<span>S</span>
                              </span>
                            )}
                          />
                          {(refund_button_show || this.state.refund_button_show) ? (
                            <button onClick={this.dRefund} disabled={this.state.loading} style={{marginRight:5}} className="btn btn-round btn-primary btn-lg">REFUND</button>
                          ):(
                            null
                          )}
                          <button onClick={this.dBuy} disabled={this.state.loading} className="btn btn-round btn-primary btn-lg">BUY</button>
                        </div>
                      </div>
                      <hr className="mt-4 mb-4" />
                      <div className="d-flex align-items-center">
                        <div className="mr-auto">
                          <h6>Commission ------</h6>
                          <h5>${this.state.getCommison}</h5>
                        </div>
                        <div>
                          <button onClick={this.dWithdraw} disabled={this.state.loading} className="btn btn-round btn-success btn-lg">WITHDRAW</button>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </section>
          )}

          {this.state.userInfo.userType == 'stockiest' && (
            <section className="pt-0 pb-0">
              <div className="container">
                <div className="md_container">
                  
                  <div className="shadow_box p-4">
                    <div className="shadow_border">
                      <h6>Make Retailer</h6>
                      <div className="row align-items-center">
                        <div className="col-md-9 col-lg-9">
                          <div className="mb-0">
                            <input type="text" placeholder="Address" className="form-control"
                              value={this.state.player_address || ''}
                              onChange={(e)=>this.setState({player_address:e.target.value})}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3 text-right">
                          <button onClick={this.doMakeDistributor} disabled={this.state.loading} className="btn btn-round btn-block btn-primary btn-lg">SUBMIT</button>
                        </div>
                      </div>
                    <hr className="mt-4 mb-4" />
                      <div className="d-flex align-items-center">
                        <div className="mr-auto">
                          <h6>Commission ------</h6>
                          <h5>${this.state.getCommison}</h5>
                        </div>
                        <div>
                          <button onClick={this.dWithdraw} disabled={this.state.loading} className="btn btn-round btn-success btn-lg">WITHDRAW</button>
                        </div>
                      </div>
                    </div>
                  </div>
                
                </div>
              </div>
            </section>
          )}
          
          {this.state.userInfo.userType != 'distributor' && (
            <section className="pt-4 pb-4">
              <div className="container">
                <div className="md_container container_fill">
                  <div className="shadow_box mb-0 p-4">
                    <div className="shadow_border">
                      <div className="heading-text md_heading mb-4">
                        <h2>User History</h2>
                      </div>
                      <div className="winning_no_table table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>Game ID</th>                            
                              <th><span className="table_buy_balls">Buy Balls</span></th>
                              <th>winning numbers</th>
                              <th>Winning Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.user_history.length ? (
                              this.state.user_history.length>0 ? (
                                this.state.user_history.map(function(val, index){
                                  let class_name = 'lebel_'+val.level;
                                  if(val.level == 0){
                                    class_name = 'current_user';
                                  }
                                  return (
                                    <tr key={`cl${index}`} className={class_name}>
                                      <td>{val.game_id}</td>                                    
                                      <td>
                                        <div className="balls_box">
                                          {default_lottery_numbers.map(function(ball, index){
                                            if(val.getHistory[index] > 0){
                                              return (
                                                <div key={`buy_ball${index}`}>
                                                  <button className="balls">{ball}</button>
                                                  <span className="number">${val.getHistory[index]?val.getHistory[index]/1e8:0}</span>
                                                </div>
                                              )
                                            }else{
                                              return null;
                                            }
                                            
                                          })}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <span className={`winning_ball_bg selected_ball${val.winingNo}`}>{val.winingNo}</span>
                                        </div>
                                      </td>
                                      <td>${parseInt(val.rewardInfo)?parseInt(val.rewardInfo)/1e8:0}</td>
                                      <td>
                                        {parseInt(val.rewardInfo) > 0 ? (
                                          val.claimInfo?<button disabled={true} className="btn btn-round btn-success btn-lg">Claimed</button>:
                                          <button onClick={()=>this.claimReward(val.game_id)} disabled={this.state.loading} className="btn btn-round btn-primary btn-lg">Claim</button>
                                        ):(
                                          <img className='oops_img' src="img/oops.png" alt="" />
                                        )}
                                        
                                      </td>
                                    </tr>
                                  )
                                })
                              ):(null)
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">No Data Available!</td>
                              </tr>
                            )}
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {this.state.userInfo.userType == 'distributor' && (
            <section className="pt-4 pb-4">
              <div className="container">
                <div className="md_container container_fill">
                  <div className="shadow_box mb-0 p-4">
                    <div className="shadow_border">
                      <div className="heading-text md_heading mb-4">
                        <h2>User History</h2>
                      </div>
                      <div className="winning_no_table table-responsive">
                        <table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>Game ID</th>                            
                              <th><span className="table_buy_balls">Buy Balls</span></th>
                              <th>winning numbers</th>
                              <th>Winning Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.distributor_history.length ? (
                              this.state.distributor_history.length>0 ? (
                                this.state.distributor_history.map(function(val, index){
                                  let class_name = 'lebel_'+val.level;
                                  if(val.level == 0){
                                    class_name = 'current_user';
                                  }
                                  return (
                                    <tr key={`cl${index}`} className={class_name}>
                                      <td>{val.game_id}</td>                                    
                                      <td>
                                        <div className="balls_box">
                                          {default_lottery_numbers.map(function(ball, index){
                                            return (
                                              <div key={`d_buy_ball${index}`}>
                                                <button className="balls">{ball}</button>
                                                <span className="number">${val.getDistributorData[index]?val.getDistributorData[index]/1e8:0}</span>
                                              </div>
                                            )                                            
                                          })}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <span className={`winning_ball_bg selected_ball${val.winingNo}`}>{val.winingNo}</span>
                                        </div>
                                      </td>
                                      <td>${parseInt(val.rewardInfo)?parseInt(val.rewardInfo)/1e8:0}</td>
                                      <td>
                                        {parseInt(val.rewardInfo) > 0 ? (
                                          val.claimInfo?<button disabled={true} className="btn btn-round btn-success btn-lg">Claimed</button>:
                                          <button onClick={()=>this2.claimReward(val.game_id)} disabled={this2.state.loading} className="btn btn-round btn-primary btn-lg">Claim</button>
                                        ):(
                                          <p>Oops!! You lost</p>
                                        )}
                                        
                                      </td>
                                    </tr>
                                  )
                                })
                              ):(null)
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">No Data Available!</td>
                              </tr>
                            )}
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="pt-4 pb-4 position-relative">
          <div className="shape2 left_shape"></div>
            <div className="shape right_shape"></div>
            <div className="container">
              <div className="heading-text text-center mb-4">
                <h2>Play Now!</h2>
              </div>
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <div className="step_box">
                    <h6>Step 1</h6>
                    <h4>Create Account</h4>
                    <p>Register on the platform and create your game account and complete the process with a deposit.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="step_box active">
                    <h6>Step 2</h6>
                    <h4>Select your number</h4>
                    <p> To play games on the platform select your preferred number starting from 0 to 9 and a preferred distributor.</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="step_box">
                    <h6>Step 3</h6>
                    <h4>Withdraw Earnings</h4>
                    <p>Enter your wallet address and select the preferred method of withdrawal to withdraw your earnings. </p>
                  </div>
                </div>
              </div>
              <hr className="mb-4 mt-4 big" />
              <div className="row">
                <div className="col-md-8 col-lg-8 col-sm-8">
                  <h4 className="mb-3"><strong>Winning Criteria!</strong></h4>
                  <h5 className="mb-3">Being a winner at number games is about much more than just getting lucky.</h5>
                  <p>To be a winner, keep in mind that there are a few extra factors to take into account if you want to come out on top. Being a casino gamer requires you to take a nuanced approach and tweak every possible variable to ensure that the games go their way. Employ consistent gambling strategies to size up your profits on the platform.</p>
                  <p>Choose a suitable number on the platform, and analyze several factors, game security, and payout processing time. As cryptocurrencies are volatile assets, so evaluate currency-related risks and be prepared for situations in which your crypto may lose value. Lastly, if you trust your analysis, playing with your favorite crypto that's gaining value is undoubtedly a more profitable approach.</p>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4">
                  <img className="img-fluid" src="img/Winning.png" alt="" />
                </div>
              </div>
              <hr className="mb-4 mt-4 big" />
            
            </div>
          </section>

          <section className="pb-5 position-relative">
          <div className="container">
              <div className="md_container">
                <div className="mt-0">
                  <div className="">
                    <div className="accordion_border">
                      <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                       Are number games best alternatives compared to traditional ones?
                      </div>
                      <div className="accordion_body">
                        <p>If gambling with number isn’t a hassle for you, then there is no reason for you not to play casino games. The blockchain-based crypto gambling sites include the top game providers, a seamless gaming website design, instant customer support, lumpsum bonuses, and much more.</p>
                      </div>
                    </div>
                    <div className="accordion_border">
                      <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                        How does a number game work?
                      </div>
                      <div className="accordion_body">
                        <p>Same to traditional casino gaming platforms, number-based casino games work in a similar way. You select your distributor and buy the number of your choice to play with. Simply start playing on the platform by creating an account and play with your favorite numbers in return for earnings. </p>
                      </div>
                    </div>
                    <div className="accordion_border">
                      <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                        Is gambling legal?
                      </div>
                      <div className="accordion_body">
                        <p>Yes, it is legal. however, be aware of which jurisdiction you are playing from and if the casino accepts players from it. For the number games to remain legal, they must not violate any regulations for a city or a state and cannot involve similar events provided by a platform.</p>
                      </div>
                    </div>
                    <div className="accordion_border">
                      <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                        Are number games safe?
                      </div>
                      <div className="accordion_body">
                        <p>Number gaming platforms provide the same level of regulation and security that traditional casinos provide. So, while playing you feel confident that the games will be fair, and you can feel confident in the level of security provided by the platform.</p>
                      </div>
                    </div>
                    <div className="accordion_border">
                      <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                        Why gamble on Xakpot?
                      </div>
                      <div className="accordion_body">
                        <p>Xakpot is a leader in number games, we provide you with a complete set of number-based gambling games generating huge earnings. Our platform supports multiple players and allows players to enjoy the best of both worlds.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    },
  }
  
  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;